import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from './constants';
import { erc20ABI, pancakeLPABI, contracts, tokens } from 'features/configure';
import axios from 'axios';
import { ChainId, Fetcher, WETH, Token } from "@pancakeswap-libs/sdk-v2";
import BigNumber from 'bignumber.js';

const ethers = require('ethers');

export function fetchPrice({ web3 }) {
  return dispatch => {
    dispatch({
      type: FETCH_PRICE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {

      const contract = new web3.eth.Contract(erc20ABI, tokens.wasabi.address);
      const lpContract = new web3.eth.Contract(pancakeLPABI, contracts.wasabiWBNBLp.address);
      const bnblpContract = new web3.eth.Contract(pancakeLPABI, contracts.wbnbBUSDLp.address);
      // const WASABI = new Token(ChainId.MAINNET, tokens.wasabi.address, 18);

      Promise.all([
        bnblpContract.methods.getReserves().call(),
        contract.methods.totalSupply().call(),
        lpContract.methods.getReserves().call(),
        lpContract.methods.totalSupply().call(),
        // Fetcher.fetchPairData(WETH[ChainId.MAINNET], WASABI, new ethers.providers.Web3Provider(web3.eth.currentProvider))
      ]).then(data => {
        dispatch({
          type: FETCH_PRICE_SUCCESS,
          data
        });
        resolve(data);
      })
        .catch(error => {
          dispatch({
            type: FETCH_PRICE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceData } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceData: state.price.priceData
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceData
  };
}

export function reducer(state = { fetchPricePending: false, priceData: [] }, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_SUCCESS:

      console.log();
      const wbnbPrice = parseFloat((new BigNumber(action.data[0]._reserve1)).div(new BigNumber(action.data[0]._reserve0)).toString());
      const wasabiPrice = parseFloat((new BigNumber(action.data[2]._reserve1)).div(new BigNumber(action.data[2]._reserve0)).times(parseFloat(wbnbPrice)).toString());

      const wasabiCirculatingSupply = action.data[1];
      const wasabiCMC = wasabiCirculatingSupply * wasabiPrice;
      const lpData = { ...action.data[2], totalSupply: action.data[3] };

      const lpTotalSupply = lpData && lpData.totalSupply ? lpData.totalSupply : 1;
      const reserve0 = lpData && lpData._reserve0 ? lpData._reserve0 : 0;
      return {
        ...state,
        priceData: { wasabiPrice: parseFloat(wasabiPrice), wasabiCMC: wasabiCMC, wasabiCirculatingSupply: wasabiCirculatingSupply, wbnbPrice: wbnbPrice, lpData: lpData, lpTotalSupply, reserve0 },
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    default:
      return state;
  }
}
