import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    COMPETITION_WITHDRAW_REWARD_BEGIN,
    COMPETITION_WITHDRAW_REWARD_SUCCESS,
    COMPETITION_WITHDRAW_REWARD_FAILURE,
} from './constants';
import { withdrawReward } from '../../web3';

export function fetchWithdrawCompetitionReward({ address, web3, amount }) {
    return dispatch => {
        dispatch({
            type: COMPETITION_WITHDRAW_REWARD_BEGIN
        });

        const promise = new Promise((resolve, reject) => {
            withdrawReward({ web3, address, amount, dispatch })
                .then(data => {
                    dispatch({
                        type: COMPETITION_WITHDRAW_REWARD_SUCCESS,
                        data,
                    });
                    resolve(data);
                })
                .catch(error => {
                    dispatch({
                        type: COMPETITION_WITHDRAW_REWARD_FAILURE
                    });
                    reject(error.message || error);
                });
        });
        return promise;
    };
}

export function useFetchWithdrawCompetitionReward() {
    const dispatch = useDispatch();

    const { withdrawRewardPending } = useSelector(state => ({
        withdrawRewardPending: state.pools.withdrawRewardPending
    }));

    const boundAction = useCallback(
        (data) => {
            return dispatch(fetchWithdrawCompetitionReward(data));
        },
        [dispatch]
    );

    return {
        fetchWithdrawCompetitionReward: boundAction,
        withdrawRewardPending,
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case COMPETITION_WITHDRAW_REWARD_BEGIN:
            return {
                ...state,
                withdrawRewardPending: true
            };

        case COMPETITION_WITHDRAW_REWARD_SUCCESS:
            return {
                ...state,
                withdrawRewardPending: false
            };

        case COMPETITION_WITHDRAW_REWARD_FAILURE:
            return {
                ...state,
                withdrawRewardPending: false
            };

        default:
            return state;
    }
}
