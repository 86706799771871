import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import styles from './styles';
import { useTheme } from '@material-ui/core/styles';
import ReorderIcon from '@material-ui/icons/Reorder';
import EqualizerIcon from '@material-ui/icons/Equalizer';
const useStyles = makeStyles(styles);

const checkSelected = (location, linkTo) => {
  if (linkTo.length < 5) return location == ("#" + linkTo)
  return location.indexOf("#" + linkTo) >= 0
}

const renderItem = (name, icon, linkTo, location, classes, hovered, setHovered) => {
  const selected = checkSelected(location, linkTo)
  const imgName = icon + "_" + (selected || hovered[name] ? "green" : "white") + ".png"
  return (
    <ListItem button component={Link} to={linkTo} selected={selected} classes={{ root: classes.listItems }}
      disabled={!linkTo}
      onMouseOver={() => setHovered({ ...hovered, [name]: true })}
      onMouseOut={() => setHovered({ ...hovered, [name]: false })}>
      <img src={require("assets/img/" + imgName)}
        style={{ width: 20, height: 20 }} />
      <span classes={{ root: classes.menuLink }} >{name}</span>
    </ListItem>
  )
}
const renderBlankTargetItem = (name, icon, linkTo, location, classes, hovered, setHovered) => {
  const selected = checkSelected(location, linkTo)
  const imgName = icon + "_" + (selected || hovered[name] ? "green" : "white") + ".png"
  return (
    <ListItem button component={"a"} href={linkTo} target="_blank" classes={{ root: classes.listItems }}
      onMouseOver={() => setHovered({ ...hovered, [name]: true })}
      onMouseOut={() => setHovered({ ...hovered, [name]: false })}>
      <img src={require("assets/img/" + imgName)}
        style={{ width: 20, height: 20 }} />
      <span classes={{ root: classes.menuLink }} >{name}</span>
    </ListItem>
  )
}
const MenuItems = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [hovered, setHovered] = useState({})
  const theme = useTheme();
  let currentLocation = window.location.hash;
  return (
    <div style={{ overflow: 'auto', height: "calc(100vh - 165px)" }}>
      {renderItem('Dashboard', "dashboard", "/app", currentLocation, classes, hovered, setHovered)}
      {renderItem('My Stats', "stats", "/app/stats", currentLocation, classes, hovered, setHovered)}
      {renderItem('Competition', "competition", "/app/competition", currentLocation, classes, hovered, setHovered)}
      {renderItem('Strategy', "strategy", "/app/strategies", currentLocation, classes, hovered, setHovered)}
      {renderItem('Minter', "minter", "/app/minters", currentLocation, classes, hovered, setHovered)}
      {renderItem('Farm', "farm", "/app/farms", currentLocation, classes, hovered, setHovered)}
      {renderItem('WASABI Locker', "locker", "/app/locker", currentLocation, classes, hovered, setHovered)}
      {renderItem('Airdrops', "airdrops", "/app/airdrops", currentLocation, classes, hovered, setHovered)}
      {renderItem('Collector', "collector", "/app/collectors", currentLocation, classes, hovered, setHovered)}
      {renderItem('Boost Calculator', "calculator", "/app/calculator", currentLocation, classes, hovered, setHovered)}


      {renderItem('Fee Schedule', "fee", "/app/fee", currentLocation, classes, hovered, setHovered)}
      {renderBlankTargetItem('Docs', "doc", "https://wasabix-finance.gitbook.io/wasabix_finance/", currentLocation, classes, hovered, setHovered)}
      {renderBlankTargetItem('Audit Report', "audit", "https://wasabix-finance.gitbook.io/wasabix_finance/code/certik-audit-report", currentLocation, classes, hovered, setHovered)}
      {renderItem('Vote', "vote", "", currentLocation, classes, hovered, setHovered)}
    </div>
  );
};

export default MenuItems;
