import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  useFetchFeeData
} from 'features/fee/redux/hooks';
import { useConnectWallet } from 'features/home/redux/hooks';
import { fee } from 'features/configure'
import CustomTable from 'components/CustomTable/CustomTable.js';
import {
  Link,
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    fontWeight: 1000,
    marginTop: 20,
    color: theme.palette.text.secondary
  },
  description: {
    fontSize: "0.9rem",
    lineHeight: 2, padding: "10px 20px"
  },
  value: {
    fontWeight: 1000,
    color: theme.palette.text.primary
  },
  lineValue: {
    fontWeight: 1000,
    textDecoration: "underline",
    color: theme.palette.text.primary
  },
  removeValue: {
    color: theme.palette.text.secondary,
    fontWeight: 1000,
    textDecoration: "line-through"
  }

}));

export default function FeeField({ type }) {
  const { address } = useConnectWallet();
  const classes = useStyles();
  const { feeData, fetchFeeData } = useFetchFeeData();
  const lastLevel = fee.length - 1
  const isVip = feeData && feeData.userLevelData.level > 0
  useEffect(() => {
    if (address) {
      fetchFeeData()
      const id = setInterval(() => {
        fetchFeeData()
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  let valueKey = "strategyWithdrawFee"
  if (type == "minter") {
    valueKey = "minterFee"
  }
  if (type == "farm") {
    valueKey = "farmWithdrawFee"
  }

  return (
    <div className={classes.description}>
      <div >
        <h1 className={classes.title}>
          Fee Structure
        </h1>
        <div >
          Your are <Link className={classes.lineValue} to={"/app/fee"}>VIP {feeData ? feeData.userLevelData.level : "-"}</Link><br />
          {feeData && feeData.userLevelData.level !=9 ? <>
            You only need to increase <span className={classes.value}>
              {feeData && !isNaN(feeData.userVeWasabi) ? parseFloat(feeData.nextLevelData.range[0] - feeData.userVeWasabi).toFixed(2) : "-"}
            </span> veWASABI to save {
              feeData ? parseFloat((feeData.userLevelData[valueKey] - feeData.nextLevelData[valueKey]) * 100 / feeData.userLevelData[valueKey]).toFixed(2) : "-"
            }% more in fee. <Link to={"/app/locker?y=700"} className={classes.lineValue}>{"Increase Now>>"}</Link>
            </>:<>Please enjoy your top level discount!</>}

        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        {(type == "strategyDeposit" || type == "strategyWithdraw") &&
          <div>
            <div>
              Deposit fee: <span className={classes.value}>0%</span>
            </div>
            {type == "strategyWithdraw" && <div>
              Withdraw fee: <span className={isVip ? classes.removeValue : classes.value}> 0.2%
              </span>
              <span className={classes.value}>
                {isVip ? ` → ${parseFloat(100 * feeData.userLevelData.strategyWithdrawFee).toFixed(2)}%
            (${parseFloat((feeData.firstLevelData.strategyWithdrawFee - feeData.userLevelData.strategyWithdrawFee) * 100 / feeData.firstLevelData.strategyWithdrawFee).toFixed(2)}% off)` : ""}
              </span>
            </div>}
          </div>
        }

        {type == "minter" &&
          <div>
            <div>
              Borrow Interest rate: <span className={classes.value}>0%</span>
            </div>
            <div>
              Borrow(Minting) one time fee:
              <span className={isVip ? classes.removeValue : classes.value}> 0.2%
              </span>
              <span className={classes.value}>{isVip ? ` → ${parseFloat(100 * feeData.userLevelData.minterFee).toFixed(2)}%
            (${parseFloat((feeData.firstLevelData.minterFee - feeData.userLevelData.minterFee) * 100 / feeData.firstLevelData.minterFee).toFixed(2)}% off)` : ""}
              </span>
            </div>
            <div>
              Repay/Liquidate fee: <span className={classes.value}>0%</span>
            </div>
          </div>
        }

        {type == "farm" &&
          <div>
            <div>
              Deposit fee: <span className={classes.value}>0%</span>
            </div>
            <div>
              Harvest fee: <span className={classes.value}>0%</span>
            </div>
            <div>
              Withdraw fee in 72 hrs: <span className={classes.value}> 1%
              </span>
              {/* <span className={classes.value}>{isVip ? ` → ${parseFloat(feeData.userLevelData.farmWithdrawFee).toFixed(2)}
            (${parseFloat((feeData.firstLevelData.farmWithdrawFee - feeData.userLevelData.farmWithdrawFee) * 100 / feeData.firstLevelData.farmWithdrawFee).toFixed(2)}% off)` : ""}
              </span> */}
            </div>
            <div>
              Withdraw fee after 72 hrs: <span className={isVip ? classes.removeValue : classes.value}> 0.2%
              </span> <span className={classes.value}>{isVip ? ` → ${parseFloat(feeData.userLevelData.farmWithdrawFee * 100).toFixed(2)}%
            (${parseFloat((feeData.firstLevelData.farmWithdrawFee - feeData.userLevelData.farmWithdrawFee) * 100 / feeData.firstLevelData.farmWithdrawFee).toFixed(2)}% off)` : ""}
              </span>
            </div>
          </div>
        }

      </div>
    </div>
  );
}
