import { pools } from '../../configure';

const tokens = {};

pools.forEach(({ token, tokenAddress, earnedToken, earnedTokenAddress }) => {
  tokens[token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0,
  };
  tokens[earnedToken] = {
    tokenAddress: earnedTokenAddress,
    tokenBalance: 0,
  };
});

const alUsd = { allowance: 0, checkApprovalPending: false, fetchApprovalPending: false };

const initialState = {
  pools,
  tokens,
  alUsd,
  apys: {},
  fetchBalancesDone: false,
  fetchBalancesPending: false,
  fetchApprovalPending: {},
  fetchTransmuterDetailDone: false,
  fetchTransmuterDetailPending: false,
  details: { wabusd: {}, wabnb: {} },
  forceTransmuteDetails: { wabusd: [], wabnb: [] },
};

export default initialState;
