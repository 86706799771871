import React, { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { useParams } from "react-router-dom";
import { useConnectWallet } from 'features/home/redux/hooks';
import {
  useFetchVaultDetail
} from 'features/vault/redux/hooks';
import {
  useCheckDaiApproval,
  useFetchDaiApproval,
  useFetchDeposit,
  useFetchEthBalance
} from 'features/vault/redux/hooks';
import { inputFinalVal } from 'features/helpers/utils';
import { byDecimals } from 'features/helpers/bignumber';
import Button from 'components/CustomButtons/Button.js';
import styles from './styles';
import Container from '@material-ui/core/Container';
import { contracts, tokens } from '../../configure';
import { useSnackbar } from 'notistack';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(styles);

export default function Deposit() {

  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address } = useConnectWallet();
  const { tokenId, strategyId } = useParams();
  const contractAddress = contracts.vault[tokenId][strategyId].address;
  const tokenAddress = tokens[tokenId].address;
  const decimals = tokens[tokenId].decimals;

  const { details, fetchVaultDetail } = useFetchVaultDetail(tokenId, strategyId);

  const [currency, setCurrency] = useState('WETH');
  const [state, setState] = useState({
    WETH: { amount: '', slider: 0, },
    ETH: { amount: '', slider: 0, }
  });

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const { allowance, checkApproval } = useCheckDaiApproval();

  const { fetchDaiApproval, fetchApprovalPending } = useFetchDaiApproval();
  const { fetchDeposit, fetchDepositPending } = useFetchDeposit();

  const [isNeedApproval, setIsNeedApproval] = useState(true);
  const [approvalAble, setApprovalAble] = useState(true);

  const [depositAble, setDepositAble] = useState(true);

  const { fetchEthBalance, ethBalance } = useFetchEthBalance();

  useEffect(() => {
    if (web3, address) {
      fetchEthBalance({ web3, address });

      const id = setInterval(() => {
        fetchEthBalance({ web3, address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  useEffect(() => {
    setIsNeedApproval(Boolean(allowance === 0));
  }, [allowance]);

  useEffect(() => {
    setApprovalAble(!Boolean(fetchApprovalPending));
  }, [fetchApprovalPending]);

  useEffect(() => {
    setDepositAble(!Boolean(fetchDepositPending));
  }, [fetchDepositPending]);

  useEffect(() => {
    if (address) {
      checkApproval(tokenAddress, contractAddress);

      const id = setInterval(() => {
        checkApproval(tokenAddress, contractAddress);
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, tokenAddress, contractAddress]);

  const onApproval = () => {
    fetchDaiApproval(tokenAddress, contractAddress);
  };

  const onDeposit = () => {
    let amountValue;
    if (currency === 'WETH') {
      amountValue = state.WETH.amount
        ? state.WETH.amount.replaceAll(',', '')
        : state.WETH.amount;
      fetchDeposit({
        address,
        web3,
        amount: new BigNumber(amountValue)
          .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
          .toString(10),
        tokenId,
        strategyId,
        isETH: false
      })
        .then(() => {
          enqueueSnackbar(t('Vault-DepositSuccess'), { variant: 'success' });
          setState({
            ...state, [currency]: {
              amount: '',
              slider: 0
            }
          });
        })
        .catch(error => enqueueSnackbar(t('Vault-DepositError', { error }), { variant: 'error' }));
    } else {
      amountValue = state.ETH.amount
        ? state.ETH.amount.replaceAll(',', '')
        : state.ETH.amount;
      fetchDeposit({
        address,
        web3,
        amount: new BigNumber(amountValue)
          .multipliedBy(new BigNumber(10).exponentiatedBy(18))
          .toString(10),
        tokenId,
        strategyId,
        isETH: true
      })
        .then(() => {
          enqueueSnackbar(t('Vault-DepositSuccess'), { variant: 'success' });
          setState({
            ...state, [currency]: {
              amount: '',
              slider: 0
            }
          });
        })
        .catch(error => enqueueSnackbar(t('Vault-DepositError', { error }), { variant: 'error' }));
    }
  };

  const setMaximumInput = event => {
    const { name } = event.target;

    let value;
    if (currency === 'WETH') {
      value = details.daiBalance;
    } else {
      value = ethBalance;
    }
    if (value) {
      value = value.replaceAll(',', '')
    }

    let target = name || currency

    setState({
      ...state, [target]: {
        amount: inputFinalVal(value, value, decimals),
      }
    });
  }

  const changeDetailInputValue = event => {
    const { value, name } = event.target;

    let total;
    if (currency === 'WETH') {
      total = details.daiBalance;
    } else {
      total = ethBalance;
    }

    let sliderNum = 0;
    let inputVal = 0;
    if (value) {
      inputVal = Number(value.replaceAll(',', ''));
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }

    let target = name || currency
    setState({
      ...state, [target]: {
        amount: inputFinalVal(value, total, decimals),
        slider: sliderNum,
      }
    });
  };

  return (
    <div className={classes.detailContainer} >
      {/* <IconToolTip>
        Please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/' target='_blank'>this link</a> to see more details.
      </IconToolTip> */}
      <Container maxWidth="md" disableGutters={true}>
        <div>
          {tokenId === 'weth' ?
            <h3 className={classes.detailHeader}>Deposit WETH/ETH in order to borrow {tokens[tokenId].correspondingMintableToken}</h3>
            :
            <h3 className={classes.detailHeader}>Deposit {tokens[tokenId].token} in order to borrow {tokens[tokenId].correspondingMintableToken}</h3>
          }
          {tokenId === 'weth' ?
            <div className={classes.detailContent}>{currency} Balance: {currency === 'WETH' ? details.daiBalance : ethBalance}</div>
            :
            <div className={classes.detailContent}>{t('Vault-Balance')}: {details.daiBalance ? new BigNumber(details.daiBalance).toFormat() : 0}</div>
          }
        </div>

        <FormControl fullWidth variant="outlined" className={classes.numericInput}>
          {tokenId === 'weth' ?
            <CustomOutlinedInput value={state[currency].amount} onChange={changeDetailInputValue} setMax={setMaximumInput}
              startAdornment={
                <InputAdornment position="start" >
                  <FormControl style={{ minWidth: 120 }} focused={false}>
                    <Select
                      className={classes.select}
                      value={currency}
                      onChange={handleCurrencyChange}
                    >
                      <MenuItem value={"WETH"}>WETH</MenuItem>
                      <MenuItem value={"ETH"}>ETH</MenuItem>
                    </Select>
                  </FormControl>
                </InputAdornment>
              } />
            :
            <CustomOutlinedInput value={state[currency].amount} onChange={changeDetailInputValue} setMax={setMaximumInput} />
          }

        </FormControl>
        {tokenId == "lusd" ?
          <>
            <Grid container item justify="center" alignItems="center">
              <Grid item xs={6} className={classes.detailText}>
                Kickback rate
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        A rate between 0 and 100% set by the Frontend Operator that determines the fraction of LQTY that will be paid out as a kickback to the Stability Providers using the frontend. Wasabix's Frontend takes 0% of the rewards.
                        <a href="https://docs.liquity.org/faq/frontend-operators#what-is-the-kickback-rate" target="_blank"> More</a>
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <IconButton>
                    <HelpIcon style={{ fontSize: "0.95rem" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6} className={classes.detailText}>100%</Grid>
              <Grid item xs={6} className={classes.detailText}>
                Frontend Operator
              </Grid>
              <Grid item xs={6} className={classes.detailText}>Wasabix Finance</Grid>
            </Grid>
            <div className={classes.detailText} style={{ marginTop: 30 }}>
              Guideline - Once deposited, the LUSD are deposited into the Liquity’s stability pool. The compounded yield is harvested from the stability pool.
              <a href="https://wasabix-finance.gitbook.io/wasabix_finance/liquity/how_it_works" target="_blank"> More</a>
            </div>
          </>
          : ''}

        <div className={classes.showDetailButton}>
          {currency === 'WETH' ?
            //WETH
            isNeedApproval ? (
              <Button
                fullWidth
                color="green"
                disabled={!Boolean(approvalAble)}
                onClick={onApproval}
              >
                {t('Approve')}
              </Button>
            ) : (
              <Button
                fullWidth
                color="green"
                disabled={!Boolean(depositAble) || !Boolean(state.WETH.amount) || state.WETH.amount === "0"}
                onClick={() => {
                  onDeposit()
                }}
              >
                {t('Deposit')}
              </Button>
            )
            :
            //ETH
            <Button
              fullWidth
              color="green"
              disabled={!Boolean(depositAble) || !Boolean(state.ETH.amount) || state.ETH.amount === "0"}
              onClick={() => {
                onDeposit()
              }}
            >
              {t('Deposit')}
            </Button>
          }
        </div>
      </Container>
    </div>
  );
}
