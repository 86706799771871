import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { POOLS_FETCH_BEGIN, POOLS_FETCH_SUCCESS, POOLS_FETCH_FAILURE } from './constants';
import { MultiCall } from 'eth-multicall';
import { erc20ABI, stakingPoolsV4ABI, tokens, contracts } from '../../configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';

const BLOCKS_PER_YEAR = new BigNumber(10512000);
const WASABI_PER_BLOCK = new BigNumber(38200000000000000);
const WITHDRAW_LOCK = 86400 * 3;

// ["2000","20000","30000","15000","33000"]
const poolsWeighting = { 0: 0.7352, 1: 0.0588, 2: 0.07352, 3: 0.0588, 4: 0.07352 };

export function fetchPools() {
  return (dispatch, getState) => {
    dispatch({
      type: POOLS_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home, price } = getState();
      const { address, web3 } = home;
      const { wasabiPrice, wbnbPrice, reserve0, lpTotalSupply, lpData } = price.priceData;
      // let wasabiPrice = new BigNumber(price.priceData.wasabiPrice);
      // let wbnbPrice = new BigNumber(price.priceData.wbnbPrice);

      const multicall = new MultiCall(web3, contracts.multicall.address);

      const stakingPoolsContract = new web3.eth.Contract(
        stakingPoolsV4ABI,
        contracts.stakingPools.address
      );

      const wasabiContract = new web3.eth.Contract(erc20ABI, tokens.wasabi.address);
      const wabnbContract = new web3.eth.Contract(erc20ABI, tokens.wabnb.address);

      const wabusdContract = new web3.eth.Contract(erc20ABI, tokens.wabusd.address);

      const wasabiwbnbContract = new web3.eth.Contract(erc20ABI, contracts.wasabiWBNBLp.address);
      const wabusdBusdContract = new web3.eth.Contract(erc20ABI, contracts.waBUSDBUSDLp.address);
      const wbnbContract = new web3.eth.Contract(erc20ABI, tokens.wbnb.address);
      const wabnbWBNBContract = new web3.eth.Contract(erc20ABI, contracts.waBNBwBNBLp.address);

      // 0: lp
      // 1: wabusd
      // 2: wabusd/busd lp
      // 3: wabnb
      // 4: wabnb/wbnb lp

      //     POOL INFO mapping
      //     IERC20 lpToken;
      //     uint256 allocPoint;
      //    uint256 lastRewardBlock;
      //    uint256 accRewardPerShare;
      //    uint256 workingSupply;
      //    uint256 totalDeposited;
      //    bool needVesting;
      //    uint256 earlyWithdrawFee;
      //    uint256 withdrawLock;
      //    bool veBoostEnabled;

      //    USER INFO mapping
      //       uint256 amount;     // How many LP tokens the user has provided.
      //       uint256 workingAmount; // actual amount * ve boost * lockup bonus
      //       uint256 rewardDebt; // Reward debt.
      //       uint256 power;
      const calls = [
        { result: stakingPoolsContract.methods.poolInfo(0) }, //0 getPoolTotalDeposited pool0
        { result: stakingPoolsContract.methods.poolInfo(1) }, //1 getPoolTotalDeposited pool1
        { result: stakingPoolsContract.methods.poolInfo(2) }, //2 getPoolTotalDeposited pool2

        { result: stakingPoolsContract.methods.getUserInfo(address, 0) }, //3 userDeposited pool0
        { result: stakingPoolsContract.methods.getUserInfo(address, 1) }, //4 userDeposited pool1
        { result: stakingPoolsContract.methods.getUserInfo(address, 2) }, //5 userDeposited pool2

        { result: wasabiwbnbContract.methods.balanceOf(address) }, //6 balance of staking target pool0
        { result: wabusdContract.methods.balanceOf(address) }, //7 balance of staking target pool1
        { result: wabusdBusdContract.methods.balanceOf(address) }, //8 balance of staking target pool2

        { result: stakingPoolsContract.methods.pendingReward(0, address) }, //9 getStakeTotalUnclaimed pool0
        { result: stakingPoolsContract.methods.pendingReward(1, address) }, //10 getStakeTotalUnclaimed pool1
        { result: stakingPoolsContract.methods.pendingReward(2, address) }, //11 getStakeTotalUnclaimed pool2

        { result: wbnbContract.methods.balanceOf(contracts.wasabiWBNBLp.address) }, //12 get wasabiWBNBLp wbnb balance
        { result: wasabiwbnbContract.methods.totalSupply() }, //13 get wasabiWBNBLp total supply

        { result: stakingPoolsContract.methods.getDepositedAt(address, 0) }, //14 getDepositedAt pool0
        { result: stakingPoolsContract.methods.getDepositedAt(address, 1) }, //15 getDepositedAt pool1
        { result: stakingPoolsContract.methods.getDepositedAt(address, 2) }, //16 getDepositedAt pool2

        { result: stakingPoolsContract.methods.poolInfo(3) }, //17
        { result: stakingPoolsContract.methods.getUserInfo(address, 3) }, //18 userDeposited pool3
        { result: wabnbContract.methods.balanceOf(address) }, //19 balance of staking target pool3
        { result: stakingPoolsContract.methods.pendingReward(3, address) }, //20 getStakeTotalUnclaimed pool3
        { result: stakingPoolsContract.methods.getDepositedAt(address, 3) }, //21 getDepositedAt pool3

        { result: stakingPoolsContract.methods.poolInfo(4) }, //22
        { result: stakingPoolsContract.methods.getUserInfo(address, 4) }, //23 userDeposited pool4
        { result: wabnbWBNBContract.methods.balanceOf(address) }, //24 balance of staking target pool4
        { result: stakingPoolsContract.methods.pendingReward(4, address) }, //25 getStakeTotalUnclaimed pool4
        { result: stakingPoolsContract.methods.getDepositedAt(address, 4) }, //26 getDepositedAt pool4

        { result: wbnbContract.methods.balanceOf(contracts.waBNBwBNBLp.address) }, //27 get wabnbWBNBContract balance
        { result: wabnbWBNBContract.methods.totalSupply() }, //28 get wabnbWBNBContract total supply
      ];

      multicall
        .all([calls])
        .then(([results]) => {
          // console.log(results);
          const pool0Staking = new BigNumber(results[0].result[5]);
          const pool1Staking = new BigNumber(results[1].result[5]);
          const pool2Staking = new BigNumber(results[2].result[5]);
          const pool3Staking = new BigNumber(results[17].result[5]);
          const pool4Staking = new BigNumber(results[22].result[5]);

          const pool0WorkingSupply = new BigNumber(results[0].result[4]);
          const pool1WorkingSupply = new BigNumber(results[1].result[4]);
          const pool2WorkingSupply = new BigNumber(results[2].result[4]);
          const pool3WorkingSupply = new BigNumber(results[17].result[4]);
          const pool4WorkingSupply = new BigNumber(results[22].result[4]);

          const userPool0Staking = new BigNumber(results[3].result[0]);
          const userPool1Staking = new BigNumber(results[4].result[0]);
          const userPool2Staking = new BigNumber(results[5].result[0]);
          const userPool3Staking = new BigNumber(results[18].result[0]);
          const userPool4Staking = new BigNumber(results[23].result[0]);

          const userPool0WorkingAmount = new BigNumber(results[3].result[1]);
          const userPool1WorkingAmount = new BigNumber(results[4].result[1]);
          const userPool2WorkingAmount = new BigNumber(results[5].result[1]);
          const userPool3WorkingAmount = new BigNumber(results[18].result[1]);
          const userPool4WorkingAmount = new BigNumber(results[23].result[1]);

          const userPool0Boost = userPool0WorkingAmount.div(userPool0Staking * 0.4);
          const userPool1Boost = userPool1WorkingAmount.div(userPool1Staking * 0.4);
          const userPool2Boost = userPool2WorkingAmount.div(userPool2Staking * 0.4);
          const userPool3Boost = userPool3WorkingAmount.div(userPool3Staking * 0.4);
          const userPool4Boost = userPool4WorkingAmount.div(userPool4Staking * 0.4);

          const userPool0Balance = new BigNumber(results[6].result);
          const userPool1Balance = new BigNumber(results[7].result);
          const userPool2Balance = new BigNumber(results[8].result);
          const userPool3Balance = new BigNumber(results[19].result);
          const userPool4Balance = new BigNumber(results[24].result);

          const userPool0Unclaimed = new BigNumber(results[9].result);
          const userPool1Unclaimed = new BigNumber(results[10].result);
          const userPool2Unclaimed = new BigNumber(results[11].result);
          const userPool3Unclaimed = new BigNumber(results[20].result);
          const userPool4Unclaimed = new BigNumber(results[25].result);

          const userPool0Unlock = parseInt(results[14].result) + WITHDRAW_LOCK;
          const userPool1Unlock = parseInt(results[15].result) + WITHDRAW_LOCK;
          const userPool2Unlock = parseInt(results[16].result) + WITHDRAW_LOCK;
          const userPool3Unlock = parseInt(results[21].result) + WITHDRAW_LOCK;
          const userPool4Unlock = parseInt(results[26].result) + WITHDRAW_LOCK;

          const wbnbBalance = new BigNumber(results[12].result);
          const lpTotalSupply = new BigNumber(results[13].result);

          const bnblpBNBBalance = new BigNumber(results[27].result);
          const bnblpTotalSupply = new BigNumber(results[28].result);

          const pool0APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[0]))
            .times(wasabiPrice)
            .div(wbnbBalance.times(pool0Staking).div(lpTotalSupply).times(2).times(wbnbPrice));
          const pool1APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[1]))
            .times(wasabiPrice)
            .div(pool1Staking);
          const pool2APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[2]))
            .times(wasabiPrice)
            .div(pool2Staking.times(2));
          const pool3APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[3]))
            .times(wasabiPrice)
            .div(pool3Staking.times(wbnbPrice));
          const pool4APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[4]))
            .times(wasabiPrice)
            .div(
              bnblpBNBBalance.times(pool4Staking).div(bnblpTotalSupply).times(2).times(wbnbPrice)
            );
          const lpPrice = lpData ? (reserve0 * wasabiPrice * 2) / lpTotalSupply : 0;
          const pool0DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[0]))
            .times(wasabiPrice)
            .times(userPool0WorkingAmount)
            .div(pool0WorkingSupply)
            .div(365);
          const pool1DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[1]))
            .times(wasabiPrice)
            .times(userPool1WorkingAmount)
            .div(pool1WorkingSupply)
            .div(365);
          const pool2DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[2]))
            .times(wasabiPrice)
            .times(userPool2WorkingAmount)
            .div(pool2WorkingSupply)
            .div(365);
          const pool3DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[3]))
            .times(wasabiPrice)
            .times(userPool3WorkingAmount)
            .div(pool4WorkingSupply)
            .div(365);
          const pool4DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[4]))
            .times(wasabiPrice)
            .times(userPool4WorkingAmount)
            .div(pool4WorkingSupply)
            .div(365);

          const output = {
            0: {
              apy: pool0APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool0Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool0Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool0Balance),
              totalStaked: convertAmountFromRawNumber(pool0Staking),
              yourStaked: convertAmountFromRawNumber(userPool0Staking),
              dailyEarn: convertAmountFromRawNumber(pool0DailyEarn),
              tokenPrice: lpPrice,
              boost: isFinite(userPool0Boost) ? userPool0Boost : 1,
              earlyWithdrawUnlockDate: userPool0Unlock,
            },
            1: {
              apy: pool1APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool1Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool1Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool1Balance),
              totalStaked: convertAmountFromRawNumber(pool1Staking),
              yourStaked: convertAmountFromRawNumber(userPool1Staking),
              dailyEarn: convertAmountFromRawNumber(pool1DailyEarn),
              tokenPrice: 1,
              boost: isFinite(userPool1Boost) ? userPool1Boost : 1,
              earlyWithdrawUnlockDate: userPool1Unlock,
            },
            2: {
              apy: pool2APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool2Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool2Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool2Balance),
              totalStaked: convertAmountFromRawNumber(pool2Staking),
              yourStaked: convertAmountFromRawNumber(userPool2Staking),
              dailyEarn: convertAmountFromRawNumber(pool2DailyEarn),
              tokenPrice: 2,
              boost: isFinite(userPool2Boost) ? userPool2Boost : 1,
              earlyWithdrawUnlockDate: userPool2Unlock,
            },
            3: {
              apy: pool3APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool3Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool3Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool3Balance),
              totalStaked: convertAmountFromRawNumber(pool3Staking),
              yourStaked: convertAmountFromRawNumber(userPool3Staking),
              dailyEarn: convertAmountFromRawNumber(pool3DailyEarn),
              tokenPrice: wbnbPrice,
              boost: isFinite(userPool3Boost) ? userPool3Boost : 1,
              earlyWithdrawUnlockDate: userPool3Unlock,
            },
            4: {
              apy: pool4APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool4Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool4Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool4Balance),
              totalStaked: convertAmountFromRawNumber(pool4Staking),
              yourStaked: convertAmountFromRawNumber(userPool4Staking),
              dailyEarn: convertAmountFromRawNumber(pool4DailyEarn),
              tokenPrice: wbnbPrice * 2,
              boost: isFinite(userPool4Boost) ? userPool4Boost : 1,
              earlyWithdrawUnlockDate: userPool4Unlock,
            },
          };

          dispatch({
            type: POOLS_FETCH_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: POOLS_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchPools() {
  const dispatch = useDispatch();

  const { context, fetchPoolsPending, fetchPoolsDone } = useSelector(
    state => ({
      context: state.pools.context,
      fetchPoolsPending: state.pools.fetchPoolsPending,
      fetchPoolsDone: state.pools.fetchPoolsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPools(data));
    },
    [dispatch]
  );

  return {
    context,
    fetchPools: boundAction,
    fetchPoolsDone,
    fetchPoolsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case POOLS_FETCH_BEGIN:
      return {
        ...state,
        fetchPoolsPending: true,
      };

    case POOLS_FETCH_SUCCESS:
      return {
        ...state,
        context: action.data,
        fetchPoolsDone: true,
        fetchPoolsPending: false,
      };

    case POOLS_FETCH_FAILURE:
      return {
        ...state,
        fetchPoolsPending: false,
      };

    default:
      return state;
  }
}
