import { competitionRewardABI, contracts } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const withdrawReward = async ({ web3, address, amount, dispatch }) => {
    const contract = new web3.eth.Contract(competitionRewardABI, contracts.competitionReward.address);
    const data = await _withdrawReward({ web3, contract, address, amount, dispatch });
    return data;
}

export const _withdrawReward = async ({ web3, contract, address, amount, dispatch }) => {
    return new Promise((resolve, reject) => {
        contract.methods
            .withdraw(amount)
            .send({ from: address })
            .on('transactionHash', function (hash) {
                console.log(hash);
                dispatch(
                    enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success',
                        },
                        hash,
                    })
                );
            })
            .on('receipt', function (receipt) {
                console.log(receipt);
                resolve();
            })
            .on('error', function (error) {
                console.log(error);
                reject(error);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
};
