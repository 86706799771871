export const FETCH_DASHBOARD_BEGIN = 'FETCH_DASHBOARD_BEGIN';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';

export const FETCH_USER_STATS_BEGIN = 'FETCH_USER_STATS_BEGIN';
export const FETCH_USER_STATS_SUCCESS = 'FETCH_USER_STATS_SUCCESS';
export const FETCH_USER_STATS_FAILURE = 'FETCH_USER_STATS_FAILURE';

export const FETCH_ASSET_STATS_BEGIN = 'FETCH_ASSET_STATS_BEGIN';
export const FETCH_ASSET_STATS_SUCCESS = 'FETCH_ASSET_STATS_SUCCESS';
export const FETCH_ASSET_STATS_FAILURE = 'FETCH_ASSET_STATS_FAILURE';

export const FETCH_STRATEGY_EARN_BEGIN = 'FETCH_STRATEGY_EARN_BEGIN';
export const FETCH_STRATEGY_EARN_SUCCESS = 'FETCH_STRATEGY_EARN_SUCCESS';
export const FETCH_STRATEGY_EARN_FAILURE = 'FETCH_STRATEGY_EARN_FAILURE';

export const FETCH_STRATEGY_APY_BEGIN = 'FETCH_STRATEGY_APY_BEGIN';
export const FETCH_STRATEGY_APY_SUCCESS = 'FETCH_STRATEGY_APY_SUCCESS';
export const FETCH_STRATEGY_APY_FAILURE = 'FETCH_STRATEGY_APY_FAILURE';

export const FETCH_AIRDROPS_BEGIN = 'FETCH_AIRDROPS_BEGIN';
export const FETCH_AIRDROPS_SUCCESS = 'FETCH_AIRDROPS_SUCCESS';
export const FETCH_AIRDROPS_FAILURE = 'FETCH_AIRDROPS_FAILURE';

export const FETCH_LOCKER_REWARDS_BEGIN = 'FETCH_LOCKER_REWARDS_BEGIN';
export const FETCH_LOCKER_REWARDS_SUCCESS = 'FETCH_LOCKER_REWARDS_SUCCESS';
export const FETCH_LOCKER_REWARDS_FAILURE = 'FETCH_LOCKER_REWARDS_FAILURE';
