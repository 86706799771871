import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useFetchPrice} from './fetchPrice';

import styles from './styles';
import { useConnectWallet } from 'features/home/redux/connectWallet';

const useStyles = makeStyles(styles);

const WasabiPrice = () => {
  const classes = useStyles();
  const { web3 } = useConnectWallet();

  const { fetchPrice, priceData } = useFetchPrice();

  useEffect(() => {
    if (web3) {
      fetchPrice({ web3 });

      const id = setInterval(() => {
        fetchPrice({ web3 });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3]);

  return (
    <div style={{ padding: 10 }}>
      <img
        alt="WASABI"
        src={require(`assets/img/wasabi.png`)}
        height={'24px'}
        className={classes.logo}
      />
      <span style={{ fontWeight: 800, paddingLeft: 5 }}>${priceData.wasabiPrice ? priceData.wasabiPrice.toFixed(3) : 'Loading...'}</span>
    </div>

  );
};

export default memo(WasabiPrice);
