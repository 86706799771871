import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useConnectWallet } from 'features/home/redux/hooks';
import HarvestCard from "features/pools/components/HarvestCard";
import FarmInfo from "features/pools/components/FarmInfo";
import FarmExtra from "features/pools/components/FarmExtra";
import { tokens } from "features/configure"
import {
  useFetchVotingEscrowDetails, useFetchClaimReward
} from 'features/pools/redux/hooks';

const useStyles = makeStyles((theme) => ({
  textValue: {
    fontWeight: 900,
    padding: "10px 0",
    fontSize: "0.95rem",
    color: theme.palette.text.primary,
  },
  contentText: {
    fontWeight: 700,
    paddingBottom: 20
  },
  card: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30,
    padding: 25
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 16, fontWeight: 600,
    textDecoration: "underline"
  },

  rightText: {
    float: "right"
  }

}));
export default function PoolDetail() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { web3, address } = useConnectWallet();
  const { enqueueSnackbar } = useSnackbar();
  const { votingEscrowDetails, fetchVotingEscrowDetails } = useFetchVotingEscrowDetails();
  const { fetchClaimReward, fetchClaimRewardPending } = useFetchClaimReward();
  // useEffect(() => {
  //   if (address) {
  //     const id = setInterval(() => {
  //     }, 10000);
  //     return () => clearInterval(id);
  //   }
  // }, [address]);


  const onHarvest = () => {
    fetchClaimReward({
      address,
      web3,
    })
      .then(() => {
        enqueueSnackbar('Vault Claim Reward Success', { variant: 'success' })
        fetchVotingEscrowDetails()
      })
      .catch(error => console.log(error));

  }

  const useContext = {
    boost: 0,
    tokenPrice: votingEscrowDetails.tokenPrice,
    apy: votingEscrowDetails.apy,
    yourStaked: votingEscrowDetails.userVeWasabi,
    totalStaked: votingEscrowDetails.totalSupply,
    dailyEarn: votingEscrowDetails.userVeWasabi/votingEscrowDetails.totalSupply * (votingEscrowDetails.rewardTokenPerYear / 365) * votingEscrowDetails.tokenPrice,
    unclaimed: votingEscrowDetails.pendingWasabi,
    unclaimedValue: votingEscrowDetails.pendingWasabi * votingEscrowDetails.tokenPrice,
    rawStaked:votingEscrowDetails.userLocked
  }
  const pool = {
    stakedTokenSymbol: "WASABI",
    rewardTokenSymbol: "WASABI",
    stakedTokenAddress: tokens.wasabi.address
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={7}>
        <FarmInfo useContext={useContext} isLock={true} extra={
          <div style={{ marginTop: 20 }}><FarmExtra pool={pool} useContext={useContext} pool={pool} isLock={true} /></div>} />
      </Grid>
      <Grid item xs={12} md={5}>
        <HarvestCard useContext={useContext} pool={pool} onClick={onHarvest} isLock={true} />
      </Grid>

    </Grid>
  );
}
