import React, { useEffect, useState } from 'react';
import Button from 'components/CustomButtons/Button.js';
import { Grid, Container, InputAdornment } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFetchPools } from 'features/pools/redux/fetchPools';
import _ from "lodash";
import {
  Link,
} from "react-router-dom";
import { useConnectWallet } from '../home/redux/hooks';
import CustomTable from 'components/CustomTable/CustomTable.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { pools } from '../configure/pools';

const useStyles = makeStyles((theme) => ({

  title: {
    color: theme.palette.text.primary,
    textAlign: "center",
    fontSize: 40, fontWeight: 800,
    paddingTop: 15,
  },
  subTitle: {
    fontSize: 20, fontWeight: 600,
    color: theme.palette.text.primary,
  },
  textTitle: {
    fontWeight: 700,
    paddingBottom: 30,
    fontSize: "1rem",
    color: theme.palette.text.secondary,
  },
  textValue: {
    fontWeight: 600,
    paddingBottom: 20,
    fontSize: "1rem",
    color: theme.palette.text.primary,
  },
  statusText: {
    fontWeight: 800,
    paddingBottom: 20,
    fontSize: "1.2rem",
    color: theme.palette.text.primary,
  },
  greenValue: {
    textAlign: "center",
    fontWeight: 900,
    padding: "50px 20px",
    fontSize: "1.3rem",
    color: theme.palette.text.green,
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "underline",
    fontWeight: 800
  }



}));
export default function FarmVote(props) {
  const classes = useStyles();
  const { address } = useConnectWallet();
  const [selectedFarm, setSelectedFarm] = useState('');
  const [votePercent, setVotePercent] = useState('');
  const { context, fetchPools } = useFetchPools();
  let filteredPools = pools.filter(pool => (pool.pid != 2 && pool.pid != 3 && pool.pid != 4));
  const farmList = _.map(filteredPools, (o) => { return { label: o.stakedTokenSymbol, value: o.stakedTokenSymbol } })
  useEffect(() => {
    if (address) {
      fetchPools()
      const id = setInterval(() => {
        fetchPools()
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  const changeVotePercent = event => {
    const { value } = event.target;
    setVotePercent(value)
  };
  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };
  return (
    <>
      <h1 className="header">
        Farm Weight Vote
      </h1>
      <div className="card" >
        <div className={classes.textTitle}>What is Farm Weight Vote</div>
        <div className={classes.textValue}>You can vote for farm weight with your veWASABI tokens(locked WASABI tokens in <Link to={"/app/locker"} className={classes.link}> Locker</Link></div>
        <div className={classes.textValue}>Farm weights are used to determine how much WASABI dose each pool get.</div>
        <div className={classes.textValue}>Farm Weight Vote will have a 14 days duration.</div>
        <div className={classes.textValue}>Adjusted APY will be applied for 14 days.</div>
        <div className={classes.textValue} style={{ paddingBottom: 0 }}>You need to have WASABI locked in the <Link to={"/app/locker"} className={classes.link}> Locker</Link> to vote for farm weights.</div>
      </div>
      <h1 className="header">
        Vote for future WASABI APYs
      </h1>
      <div className="card" >
        <div className={classes.textValue}>Voting duration: DD:HH:MM:SS</div>
        <div className={classes.textValue}>Effect date: Jun 8,2021 UTC+0</div>
        <CustomTable headers={["No.", "Pools", "Current WASABI APY", "Future WASABI APY"]}
          type="column"
          leftText={{ 0: true, 1: true }} contents={
            filteredPools.map((pool, index) =>
              [
                index + 1,
                <div><img src={require('assets/img/' + _.toLower(pool.stakedTokenSymbol) + ".png")} className={"smIcon"} alt='' />{pool.stakedTokenSymbol}</div>,
                context[pool.pid] ? isFinite(parseFloat(context[pool.pid].apy)) ? (parseFloat(context[pool.pid].apy) * 100).toFixed(2) + '%' : 'Loading...' : 'Loading...',
                "- %"
              ]
            )
          } />
        <h1 className={"header"}>Your voting status</h1>
        <div className="card" >
          <div className={classes.textValue}>You are voting with: 1117 veWASABI</div>
          <div className={classes.textValue}>Your voting weight used: 20%
            <Button color="green" size="sm" style={{ marginLeft: 10 }}>Hide my allocation</Button>
          </div>
        </div>
        <h1 className={"header"}>vote</h1>
        <div className="card" >
          <div className={classes.textValue}>Please select a farm to start voting
            <CustomDropdown
              style={{ marginLeft: 10 }}
              type={"outlined"}
              value={selectedFarm}
              data={farmList}
              onSelect={handleFarmChange}
            />
          </div>
          <div className={classes.textValue}>You wish to use % of voting power for this farm:
            <CustomOutlinedInput value={votePercent} onChange={changeVotePercent} style={{ width: 180 }} endAdornment={
              <InputAdornment position="start" >
                %
              </InputAdornment>
            } /> </div>
          <div className={classes.textValue} style={{ marginTop: 30 }}>11.8(1%) of your voting power will be allocated to this farm</div>
          <Button fullWidth color="green">Vote</Button>
        </div>
      </div>
      <div className="block">
        <h1 className="header">
          Global Voting Status
        </h1>
        <div className="card" >
          <div className={classes.statusText}>Vote this duration: 666666 veWASABI</div>
          <div className={classes.statusText}>Total veWASABI: 12344444</div>
          <div className={classes.statusText} style={{ paddingBottom: 0 }}>0.54% of veWASABI supply voted</div>
        </div>
      </div>
    </>
  );
}
