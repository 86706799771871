import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import { byDecimals, calculateReallyNum } from 'features/helpers/bignumber';
import styles from './styles';
import CollectorStatus from './components/CollectorStatus';
import Button from 'components/CustomButtons/Button.js';
import {
  Link,
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import CustomTable from 'components/CustomTable/CustomTable.js';
import {
  useFetchTransmuterDetail,
  useFetchStake,
  useFetchUnstake,
  useFetchTransmute,
  useCheckAlUsdApproval,
  useFetchAlUsdApproval
} from './redux/hooks';
import { useConnectWallet } from 'features/home/redux/hooks';
import { useSnackbar } from 'notistack';
import BigNumber from 'bignumber.js';
import ForceTransmute from './components/ForceCollect';
import { contracts, tokens } from '../configure';

export default function CollectorPage() {

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { tokenId } = useParams();
  const contractAddress = contracts.transmuter[tokenId].address;
  const tokenAddress = tokens[tokenId].address;
  const decimals = tokens[tokenId].decimals;
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address } = useConnectWallet();
  const { details } = useFetchTransmuterDetail();
  const { allowance, checkApproval } = useCheckAlUsdApproval();
  const { fetchAlUsdApproval, fetchApprovalPending } = useFetchAlUsdApproval();

  const { fetchStake, fetchStakePending } = useFetchStake();
  const { fetchUnstake, fetchUnstakePending } = useFetchUnstake();
  const { fetchTransmute, fetchTransmutePending } = useFetchTransmute();
  const [isNeedApproval, setIsNeedApproval] = useState(true);
  const [approvalAble, setApprovalAble] = useState(true);

  const [stakeBalance, setStakeBalance] = useState({
    amount: '',
    slider: 0,
  });
  const [unstakeBalance, setUnstakeBalance] = useState({
    amount: '',
    slider: 0,
  });

  useEffect(() => {
    if (address) {
      checkApproval(tokenAddress, contractAddress);

      const id = setInterval(() => {
        checkApproval(tokenAddress, contractAddress);
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, tokenAddress, contractAddress]);

  useEffect(() => {
    setIsNeedApproval(Boolean(allowance === 0));
  }, [allowance]);

  useEffect(() => {
    setApprovalAble(!Boolean(fetchApprovalPending));
  }, [fetchApprovalPending]);

  const onApproval = () => {
    fetchAlUsdApproval(tokenAddress, contractAddress);
  };

  const setStakeMaximumInput = () => {
    const total = details[tokenId].alUsdBalance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat()
      setStakeBalance({
        amount: amount.replaceAll(',', ''),
      });
    }
  };


  const onStake = () => {
    let amountValue = stakeBalance.amount
      ? stakeBalance.amount.replaceAll(',', '')
      : stakeBalance.amount;

    fetchStake({
      address,
      web3,
      amount: new BigNumber(amountValue)
        .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
        .toString(10),
      tokenId
    })
      .then(() => {
        enqueueSnackbar(t('Transmuter-StakeSuccess'), { variant: 'success' });
        setStakeBalance({
          amount: 0,
          slider: 0
        });
      })
      .catch(error => enqueueSnackbar(t('Transmuter-StakeError', { error }), { variant: 'error' }));
  };

  const setUnStakeMaximumInput = () => {
    const total = details[tokenId].stakedAlUsdBalance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat();

      setUnstakeBalance({
        amount: amount,
      });
    }
  };

  const handleUnstakeBalance = (_, sliderNum) => {
    const total = details[tokenId].stakedAlUsdBalance;
    const amount = sliderNum === 0 ? '' : sliderNum === 100 ? new BigNumber(total).toFormat() : calculateReallyNum(total, sliderNum);

    setUnstakeBalance({
      amount: amount,
      slider: sliderNum,
    });
  };

  const onUnstake = () => {
    let amountValue = unstakeBalance.amount
      ? unstakeBalance.amount.replaceAll(',', '')
      : unstakeBalance.amount;

    fetchUnstake({
      address,
      web3,
      amount: new BigNumber(amountValue)
        .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
        .toString(10),
      tokenId
    })
      .then(() => {
        enqueueSnackbar(t('Transmuter-UnstakeSuccess'), { variant: 'success' });
        setUnstakeBalance({
          amount: 0,
          slider: 0
        });
      })
      .catch(error => enqueueSnackbar(t('Transmuter-UnstakeError', { error }), { variant: 'error' }));
  };


  const changeStakeInputValue = event => {
    let value = event.target.value;
    const total = details[tokenId].alUsdBalance;

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let sliderNum = 0;
    let inputVal = 0;
    if (value) {
      inputVal = Number(value.replaceAll(',', ''));
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }

    setStakeBalance({
      amount: inputFinalVal(value, total, decimals),
      slider: sliderNum,
    });
  };

  const changeUnstakeInputValue = event => {
    let value = event.target.value;
    const total = details[tokenId].stakedAlUsdBalance;

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let sliderNum = 0;
    let inputVal = 0;
    if (value) {
      inputVal = Number(value.replaceAll(',', ''));
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }

    setUnstakeBalance({
      amount: inputFinalVal(value, total, decimals),
      slider: sliderNum,
    });
  };

  const onTransmute = isExit => {
    fetchTransmute({
      address,
      web3,
      tokenId,
      isExit
    })
      .then(() => {
        enqueueSnackbar(t('Transmuter-TransmuteSuccess'), { variant: 'success' });
      })
      .catch(error => enqueueSnackbar(t('Transmuter-TransmuteError', { error }), { variant: 'error' }));
  };
  const collectToken = tokens[tokenId]
  const tokenDetail = details[tokenId] || {}
  return (
    <>
      <Link to={"/app/collectors"} className={classes.labelLink}>{"<< back to collectors"}</Link>
      <div className={"card"} style={{ marginTop: 30, backgroundColor: theme.palette.background.paper, }}>
        <div className="vaultHeader">
          Collector: {tokens[tokenId].token} to {tokens[tokenId].baseToken}
        </div>
        <div className="vaultSubHeader">Staking your {tokens[tokenId].token} will gradually convert it into {tokens[tokenId].baseToken} over time.</div>
        <CustomTable headers={[
          `Your ${collectToken.token}`,
          `Staked ${collectToken.token}`,
          `Collectable ${collectToken.baseToken}`,
          `Your ${collectToken.baseToken}`]}
          type="outlined"
          leftText={{ 0: false }} contents={
            [[
              tokenDetail.alUsdBalance ? new BigNumber(tokenDetail.alUsdBalance).toFormat() : 0,
              tokenDetail.stakedAlUsdBalance ? new BigNumber(tokenDetail.stakedAlUsdBalance).toFormat() : 0,
              tokenDetail.transmutableDaiBalance ? new BigNumber(tokenDetail.transmutableDaiBalance).toFormat() : 0,
              tokenDetail.daiBalance ? new BigNumber(tokenDetail.daiBalance).toFormat() : 0
            ]]
          } />

        <Grid container justify="center" spacing={3} style={{ marginTop: 30 }}>
          <Grid item xs={12} md={6} style={{ marginTop: 7 }}>
            {
              isNeedApproval ? (
                <Button
                  color="green" fullWidth
                  disabled={!Boolean(approvalAble)}
                  onClick={() => onApproval(false)}
                >
                  {t('Approve')}
                </Button>
              )
                : (
                  <>

                    <CustomOutlinedInput
                      value={stakeBalance.amount}
                      onChange={changeStakeInputValue}
                      setMax={() => setStakeMaximumInput()} />
                    <Button
                      fullWidth color="green"
                      disabled={!Boolean(stakeBalance.amount)}
                      onClick={() => onStake()}>
                      Stake
                    </Button>
                  </>
                )}
          </Grid>
          <Grid item xs={12} md={6} >
            <CustomOutlinedInput
              value={unstakeBalance.amount}
              onChange={changeUnstakeInputValue}
              setMax={() => setUnStakeMaximumInput()} />
            <Button
              fullWidth color="green"
              disabled={!Boolean(unstakeBalance.amount)}
              onClick={() => onUnstake()}>
              UnStake
            </Button>
          </Grid>
          <Grid item xs={12} md={6} >
            <Button
              color="green" fullWidth
              disabled={tokenDetail.transmutableDaiBalance == '0'}
              onClick={() => onTransmute(false)}
            >
              Collect
            </Button></Grid>
          <Grid item xs={12} md={6}>
            <Button
              color="green" fullWidth
              disabled={tokenDetail.transmutableDaiBalance == '0'}
              onClick={() => onTransmute(true)}
            >
              {`Collect & Exit`}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="block" />
      <div className={"card"}>
        <CollectorStatus />
      </div>
      <ForceTransmute tokenId={tokenId} />
    </ >
  );
}
