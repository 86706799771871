import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import {
  FETCH_LOCKER_REWARDS_BEGIN,
  FETCH_LOCKER_REWARDS_SUCCESS,
  FETCH_LOCKER_REWARDS_FAILURE,
} from './constants';
import { pools } from '../../configure/pools';
import { apiUrl } from 'features/configure';
export function fetchLockerRewards({ account }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_LOCKER_REWARDS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/users/votingEscrow/rewards?chainId=56&account=` + account)
        .then(response => {
          dispatch({
            type: FETCH_LOCKER_REWARDS_SUCCESS,
            data: response.data,
          });
          resolve(response);
        })
        .catch(
          // Use rejectHandler as the second argument so that render errors won't be caught.
          error => {
            dispatch({
              type: FETCH_LOCKER_REWARDS_FAILURE,
            });
            reject(error.message || error);
          }
        );
    });
    return promise;
  };
}

export function useFetchLockerRewards() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();
  const { lockerRewards, lockerRewardsPending } = useSelector(state => ({
    lockerRewards: state.dashboard.lockerRewards,
    lockerRewardsPending: state.dashboard.lockerRewardsPending,
  }));

  const boundAction = useCallback(data => dispatch(fetchLockerRewards(data)), [dispatch]);

  return {
    lockerRewards,
    fetchLockerRewards: boundAction,
    lockerRewardsPending,
  };
}

export function reducer(state, action) {
  let { lockerRewardsPending, lockerRewards } = state;
  switch (action.type) {
    case FETCH_LOCKER_REWARDS_BEGIN:
      lockerRewardsPending = true;
      return {
        ...state,
        lockerRewardsPending,
      };

    case FETCH_LOCKER_REWARDS_SUCCESS:
      return {
        ...state,
        lockerRewards: action.data,
        lockerRewardsPending: false,
      };

    case FETCH_LOCKER_REWARDS_FAILURE:
      // The request is failed
      lockerRewardsPending = false;

      return {
        ...state,
        lockerRewards: undefined,
        lockerRewardsPending,
      };

    default:
      return state;
  }
}
