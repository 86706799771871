import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import {
  FETCH_STRATEGY_EARN_BEGIN,
  FETCH_STRATEGY_EARN_SUCCESS,
  FETCH_STRATEGY_EARN_FAILURE,
} from './constants';
import { pools } from '../../configure/pools';
import { apiUrl } from "features/configure"
export function fetchStrategyEarn(address) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_STRATEGY_EARN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      axios.get(`${apiUrl}/stats/strategies/${address}?chainId=56`).then(
        response => {
          dispatch({
            type: FETCH_STRATEGY_EARN_SUCCESS,
            data: response.data,
          });
          resolve(response);
        },
      ).catch(
        // Use rejectHandler as the second argument so that render errors won't be caught.
        error => {
          dispatch({
            type: FETCH_STRATEGY_EARN_FAILURE,
          });
          reject(error.message || error);
        }
      )
    });
    return promise;
  }
}

export function useFetchStrategyEarn() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();
  const { earnDataPending, earnData } = useSelector(
    state => ({
      earnData: state.dashboard.earnData,
      earnDataPending: state.dashboard.earnDataPending,
    })
  );

  const boundAction = useCallback(
    (address) => dispatch(fetchStrategyEarn(address)),
    [dispatch],
  );

  return {
    earnData,
    fetchStrategyEarn: boundAction,
    earnDataPending
  };
}

export function reducer(state, action) {
  let { earnData, earnDataPending } = state;
  switch (action.type) {
    case FETCH_STRATEGY_EARN_BEGIN:
      earnDataPending = true;
      return {
        ...state,
        earnDataPending,
      };

    case FETCH_STRATEGY_EARN_SUCCESS:

      return {
        ...state,
        earnData: action.data,
        earnDataPending: false
      };

    case FETCH_STRATEGY_EARN_FAILURE:
      // The request is failed
      earnDataPending = false;

      return {
        ...state,
        earnData: undefined,
        earnDataPending
      };

    default:
      return state;
  }
}
