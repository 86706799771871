import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import TotalLockedValue from '../../components/TVL/TotalLockedValue';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import Button from 'components/CustomButtons/Button.js';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useConnectWallet } from 'features/home/redux/hooks';
import {
  useFetchCompetitionReward,
  useFetchWithdrawCompetitionReward
} from 'features/pools/redux/hooks';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import styled from 'styled-components'
import { useFetchPrice } from 'components/Price/fetchPrice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '98%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '100px'
  },
  card: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    borderRadius: 20
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "center",
    fontSize: "2.3rem"
  },
  label: {
    color: theme.palette.text.primary,
    fontFamily: "Inconsolata",
    fontSize: "1rem",
    fontWeight: "800"
  },
  contentHeader: {
    color: theme.palette.text.secondary,
    lineHeight: 1.3,
    fontWeight: 700,
    fontSize: "0.9rem"
  },
  contentValue: {
    lineHeight: 1.3,
    fontWeight: 800,
    textAlign: "right",
    fontSize: "0.95rem"
  },
}));

const ordinal_suffix_of = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

// P(n,r) = n!/(n-r)!
function permutation(n, r) {
  let answer = 1;
  if (n == 0 || n == 1) {
    return answer;
  } else {
    let num = n;
    for (var i = r; i >= 1; i--) {
      answer = answer * num;
      num--;
    }
    return answer;
  }
}

export default function Competition() {
  const classes = useStyles();
  const theme = useTheme();
  const [balance, setBalance] = useState('');
  const { address, web3 } = useConnectWallet();
  const { priceData } = useFetchPrice();
  const [userReward, setUserReward] = useState(0);
  const [userRank, setUserRank] = useState(-1);
  const [userStaked, setUserStaked] = useState(0);
  const [userPoint, setUserPoint] = useState(0);
  const [userTickets, setUserTickets] = useState(0);
  const [userWinningRate, setUserWinningRate] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);

  const { competitionRewardDetails, fetchCompetitionReward, fetchCompetitionRewardPending } = useFetchCompetitionReward();
  const { fetchWithdrawCompetitionReward } = useFetchWithdrawCompetitionReward();

  const matchComputer = useMediaQuery(theme.breakpoints.up('md'));
  const matchTablet = useMediaQuery(theme.breakpoints.up('sm'));




  const StyledInfo = styled.div`
    text-shadow: 0 0 8px #49FF18, 0 0 11px #49FF18, 0 0 30px #49ff18, 2px 2px 2px rgba(101,255,152,0.35);
    margin: 0;
    padding: 0;
    text-align: center;
  `

  useEffect(() => {
    if (address) {

      fetchCompetitionReward(address)
      const id = setInterval(() => {

        fetchCompetitionReward(address)

      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);


  const setMaximumInput = event => {
    let value = competitionRewardDetails ? competitionRewardDetails.availableAmount : '0';

    if (!inputLimitPass(value, 18)) {
      return;
    }

    setBalance(inputFinalVal(value, value, 18));
  }

  const changeBalanceInputValue = event => {
    let value = event.target.value;
    const total = competitionRewardDetails ? competitionRewardDetails.availableAmount : '0';

    if (!inputLimitPass(value, 18)) {
      return;
    }

    setBalance(inputFinalVal(value, total, 18));
  };

  const onWithdraw = (amount) => {
    let amountValue = amount
      ? amount.replaceAll(',', '')
      : amount;
    const formated = new BigNumber(amountValue)
      .multipliedBy(new BigNumber(10).exponentiatedBy(18))
      .toString(10);
    fetchWithdrawCompetitionReward({
      address,
      web3,
      amount: formated
    }).then(() => {
      setBalance('');
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.card} style={{ marginBottom: 15, position: "relative" }}>
        <img
          alt=''
          src={require('../../assets/img/competition.png')}
          style={{ width: '100%' }} />
        <StyledInfo style={{
          position: "absolute", top: "42%", color: "white", left: "50%", width: '100%',
          transform: 'translate(-50%, 0)', fontSize: matchComputer ? 40 : matchTablet ? 22 : 16, fontWeight: 600
        }}>{`>> $${priceData && priceData.wasabiPrice ? new BigNumber(10000 * priceData.wasabiPrice.toFixed(0)).toFormat(0) : "Loading"}+ in Prize!! <<`}</StyledInfo>
      </div>
      {/* <div className={classes.card}>
        <h1 className={classes.title}>

          <TotalLockedValue />

        </h1>
      </div> */}

      <div className={classes.card} style={{ marginTop: 30, padding: 30, fontSize: 24 }}>
        WasabiX Competition season 1 has successfully ended! Thank you so much for your support and please stay tuned for our competition season2!<br />
        -<br />
        Lottery is on the way, stay tuned.<br />
        -<br />
        <div style={{ fontWeight: 800, color: 'red' }}>Important: please move your PLP token from v1 to v2! <Link to="/app/farms">Move Now</Link><br /></div>

      </div>

      <div className={"card"} style={{ marginTop: 30 }}>
        <h1 className={classes.title}>Competition Reward <Tooltip
          placement="right"
          interactive
          title={
            <React.Fragment>
              <Typography color="inherit">
                The competition rewards will be distributed on monthly basis in 12 tranches. The rewards will NOT need to be vested for 3 months.
              </Typography>
            </React.Fragment>
          }
        >
          <IconButton className={"tooltip"}>
            <HelpIcon style={{ fontSize: "0.95rem" }} />
          </IconButton>
        </Tooltip></h1  >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} >
            <div className={classes.contentHeader}>Initial Reward Amount:</div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.title}>
            <div className={classes.contentValue}>
              {!competitionRewardDetails || isNaN(competitionRewardDetails.unlockedAmount) ? "Loading..." : competitionRewardDetails.initialRewardAmount}</div>
          </Grid>
          <Grid item xs={12} md={6} >
            <div className={classes.contentHeader}>Unlocked Amount:</div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.title}>
            <div className={classes.contentValue}>
              {!competitionRewardDetails || isNaN(competitionRewardDetails.unlockedAmount) ? "Loading..." : competitionRewardDetails.unlockedAmount}</div>
          </Grid>
          <Grid item xs={12} md={6} >
            <div className={classes.contentHeader}>Distributed Amount:</div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.title}>
            <div className={classes.contentValue}>
              {!competitionRewardDetails || isNaN(competitionRewardDetails.unlockedAmount) ? "Loading..." : competitionRewardDetails.distributedAmount}</div>
          </Grid>
          <Grid item xs={12} md={6} >
            <div className={classes.contentHeader}>Available Amount:</div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.title}>
            <div className={classes.contentValue}>
              {!competitionRewardDetails || isNaN(competitionRewardDetails.unlockedAmount) ? "Loading..." : competitionRewardDetails.availableAmount}</div>
          </Grid>
          <Grid item xs={12} md={6} >
            <div className={classes.contentHeader}>Next Release In:</div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.title}>
            <div className={classes.contentValue}>
              {!competitionRewardDetails || isNaN(competitionRewardDetails.nextDays) ? "Loading..." : competitionRewardDetails.nextDays} days</div>
          </Grid>
          <Grid item xs={12} md={9}>
            <CustomOutlinedInput value={balance} onChange={changeBalanceInputValue} setMax={setMaximumInput} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button fullWidth
              color="green"
              style={{ marginTop: 10 }}
              disabled={!competitionRewardDetails || !Boolean(balance) || balance === "0"}
              onClick={() => {
                onWithdraw(balance)
              }}>
              Withdraw
            </Button>
          </Grid>
        </Grid>
      </div>

    </div >
  );
}
