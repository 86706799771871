export const contracts = {
  vault: {
    //alchemist
    busd: {
      alpaca: {
        address: '0x84e6Fd3595010Aa6eE461EA2BFFA03776780F412',
      },
    },
    wbnb: {
      alpaca: {
        address: '0xb4f52916414119E4212369569d9089F90070b6DC',
      },
    },
  },

  multicall: {
    address: '0x8B4B559b34C1742dBCF178BfED9bC85f6ece1C7b',
  },
  transmuter: {
    wabusd: {
      address: '0x99b399B4c79B8c51C4bc8c0Df4f49A77Cfdec340',
    },
    wabnb: {
      address: '0xf1d5A685A5E6bEC8471D6d9ad2D20aC48EcB2109',
    },
  },
  stakingPools: {
    address: '0x894CcdBED28E294482fECf10eAC5962148bf4E15',
  },
  oldStakingPools: {
    address: '0x3527640987c211df03737b2146b160ddbfcc14d2',
  },
  rewardVestings: [
    {
      address: '0x6E6a23Cfa379C3B4CcD958b1B2caB46Cf159E83E',
      token: 'WASABI',
    },
    // {
    //   address: '0x5eb3Bc0a489C5A8288765d2336659EbCA68FCd00',
    //   token: 'BURGER'
    // }
  ],
  votingEscrow: {
    address: '0x7d487Aeaf197691aA5645728c54f204be67991fF',
    MAX_LOCK_TIME: 1440,
    rewardTokens: [
      {
        address: '0x86e73212002f80C57070EfAd4765FF0117dE5aEa',
        token: 'WASABI',
        needVesting: false,
      },
    ],
  },
  competitionReward: {
    address: '0xB208dec45eDBD1179d9e275C5D459E6282d606ea',
  },
  wasabiWBNBLp: {
    address: '0x4af56f065fab006721ab686086be206eba9d1abc',
  },
  waBUSDBUSDLp: {
    address: '0x83a21c7E760F3682AB84D7A25dE79500B76bb908',
  },
  wbnbBUSDLp: {
    address: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
  },
  waBNBwBNBLp: {
    address: '0xfcBa7FDCfEfF262fDAbFaa9eb2faaEa84Aee4636',
  },
  airdrops: {
    address: '',
  },
  // yvDai: {
  //   address: '0x19D3364A399d251E894aC732651be8B0E4e85001'
  // },
  // adapter: {
  //   address: '0xB8c04f13B23cc4046C5446Efc8ABd8d0DAF467Ca'
  // },
  // wasabiWETHLp:{
  //   address: '0x4A679253410272dd5232B3Ff7cF5dbB88f295319'
  // },
  // waUSD3CRV:{
  //   address: '0x9f6664205988c3bf4b12b851c075102714869535'
  // }
};
