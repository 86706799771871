import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    FETCH_COMPETITION_REWARD_BEGIN,
    FETCH_COMPETITION_REWARD_SUCCESS,
    FETCH_COMPETITION_REWARD__FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { competitionRewardABI, contracts } from '../../configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';
import _ from 'lodash';

export function fetchCompetitionReward(address) {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_COMPETITION_REWARD_BEGIN,
        });

        const promise = new Promise((resolve, reject) => {

            const { home } = getState();
            const { web3 } = home;

            const multicall = new MultiCall(web3, contracts.multicall.address);
            const competitionRewardContract = new web3.eth.Contract(competitionRewardABI, contracts.competitionReward.address);

            let calls = [];
            calls.push({ result: competitionRewardContract.methods.getInitialRewardAmount(address) })
            calls.push({ result: competitionRewardContract.methods.getDistributedAmount(address) })
            calls.push({ result: competitionRewardContract.methods.getAvailableAmount(address) })
            calls.push({ result: competitionRewardContract.methods.startDay() })
            calls.push({ result: competitionRewardContract.methods.today() })
            multicall
                .all([calls])
                .then(([results]) => {
                    const startDay = results[3].result
                    const today = results[4].result
                    const output = {
                        initialRewardAmount: convertAmountFromRawNumber(results[0].result),
                        distributedAmount: convertAmountFromRawNumber(results[1].result),
                        availableAmount: convertAmountFromRawNumber(results[2].result),
                        nextDays: 30 - (today - startDay) % 30
                    };
                    output.unlockedAmount = convertAmountFromRawNumber(BigNumber.sum.apply(null, [new BigNumber(results[1].result), new BigNumber(results[2].result)]))
                    dispatch({
                        type: FETCH_COMPETITION_REWARD_SUCCESS,
                        data: output
                    });
                    resolve();
                })
                .catch(error => {
                    dispatch({
                        type: FETCH_COMPETITION_REWARD__FAILURE
                    });
                    reject(error.message || error);
                });
        })


        return promise;
    };
}

export function useFetchCompetitionReward() {
    const dispatch = useDispatch();

    const { competitionRewardDetails, fetchCompetitionRewardPending } = useSelector(
        state => ({
            competitionRewardDetails: state.pools.competitionRewardDetails,
            fetchCompetitionRewardPending: state.pools.fetchCompetitionRewardPending,
        }),
        shallowEqual
    );

    const boundAction = useCallback(
        address => {
            return dispatch(fetchCompetitionReward(address));
        },
        [dispatch]
    );

    return {
        competitionRewardDetails,
        fetchCompetitionReward: boundAction,
        fetchCompetitionRewardPending,
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case FETCH_COMPETITION_REWARD_BEGIN:
            return {
                ...state,
                fetchCompetitionRewardPending: true,
            };

        case FETCH_COMPETITION_REWARD_SUCCESS:
            return {
                ...state,
                competitionRewardDetails: action.data,
                fetchCompetitionRewardPending: false,
            };

        case FETCH_COMPETITION_REWARD__FAILURE:
            return {
                ...state,
                fetchCompetitionRewardPending: false,
            };

        default:
            return state;
    }
}
