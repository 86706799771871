import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  TRANSMUTER_DETAIL_FETCH_BEGIN,
  TRANSMUTER_DETAIL_FETCH_SUCCESS,
  TRANSMUTER_DETAIL_FETCH_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import {
  erc20ABI,
  alUSDABI,
  transmuterABI,
  alchmistABI,
  tokens,
  contracts,
  wethABI,
} from 'features/configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';

export function fetchAllTransmuterDetail() {
  return dispatch => {
    return Object.keys(contracts.transmuter).map(tokenId => {
      dispatch(fetchTransmuterDetail(tokenId));
    });
  };
}

export function fetchTransmuterDetail(tokenId) {
  return (dispatch, getState) => {
    dispatch({
      type: TRANSMUTER_DETAIL_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { address, web3 } = home;

      const decimals = tokens[tokenId].decimals;

      const multicall = new MultiCall(web3, contracts.multicall.address);
      const transmuterAddress = contracts.transmuter[tokenId].address;
      const transmuterContract = new web3.eth.Contract(transmuterABI, transmuterAddress);

      if (tokenId === 'wabnb') {
        const wbnbContract = new web3.eth.Contract(erc20ABI, tokens.wbnb.address);
        const wabnbContract = new web3.eth.Contract(alUSDABI, tokens.wabnb.address);

        const alchemistVesperAddress = contracts.vault['wbnb']['alpaca'].address;
        const alchemistVesperContract = new web3.eth.Contract(alchmistABI, alchemistVesperAddress);

        const calls = [
          { result: wbnbContract.methods.balanceOf(transmuterAddress) },
          { result: wabnbContract.methods.balanceOf(transmuterAddress) },
          { result: wabnbContract.methods.balanceOf(address) },
          { result: wbnbContract.methods.balanceOf(address) },
          { result: transmuterContract.methods.userInfo(address) },
          { result: alchemistVesperContract.methods.getVaultTotalDeposited(0) },
        ];

        multicall
          .all([calls])
          .then(([results]) => {
            const transmuterDaiBalance = new BigNumber(results[0].result);
            const transmuterAlUsdBalance = new BigNumber(results[1].result);
            const alUsdBalance = new BigNumber(results[2].result);
            const daiBalance = new BigNumber(results[3].result);
            const userInfo = results[4];

            let stakedAlUsdBalance = new BigNumber(0);
            let tokensInBucket = new BigNumber(0);
            let pendingdivs = new BigNumber(0);

            if (userInfo.result) {
              stakedAlUsdBalance = new BigNumber(userInfo.result[0]);
              tokensInBucket = new BigNumber(userInfo.result[2]);
              pendingdivs = new BigNumber(userInfo.result[1]);
            }

            const alpacaDepositedInVault = new BigNumber(results[5].result);

            const output = {
              transmuterDaiBalance: convertAmountFromRawNumber(transmuterDaiBalance, decimals),
              transmuterAlUsdBalance: convertAmountFromRawNumber(transmuterAlUsdBalance, decimals),
              alUsdBalance: convertAmountFromRawNumber(alUsdBalance, decimals),
              daiBalance: convertAmountFromRawNumber(daiBalance, decimals),
              stakedAlUsdBalance: convertAmountFromRawNumber(stakedAlUsdBalance, decimals),
              transmutableDaiBalance: convertAmountFromRawNumber(
                tokensInBucket.plus(pendingdivs),
                decimals
              ),
              alpacaDepositedInVault: convertAmountFromRawNumber(alpacaDepositedInVault, decimals),
            };

            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_SUCCESS,
              data: output,
              tokenId: tokenId,
            });
            resolve();
          })
          .catch(error => {
            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_FAILURE,
            });
            return reject(error.message || error);
          });
      } else {
        //waUSD
        const daiContract = new web3.eth.Contract(erc20ABI, tokens.busd.address);
        const wausdContract = new web3.eth.Contract(alUSDABI, tokens.wabusd.address);

        const alchemistAlpacaAddress = contracts.vault['busd']['alpaca'].address;

        const alchemistAlpacaContract = new web3.eth.Contract(alchmistABI, alchemistAlpacaAddress);

        const calls = [
          { result: daiContract.methods.balanceOf(transmuterAddress) },
          { result: wausdContract.methods.balanceOf(transmuterAddress) },
          { result: wausdContract.methods.balanceOf(address) },
          { result: daiContract.methods.balanceOf(address) },
          { result: transmuterContract.methods.userInfo(address) },
          { result: alchemistAlpacaContract.methods.getVaultTotalDeposited(0) },
        ];

        multicall
          .all([calls])
          .then(([results]) => {
            const transmuterDaiBalance = new BigNumber(results[0].result);
            const transmuterAlUsdBalance = new BigNumber(results[1].result);
            const alUsdBalance = new BigNumber(results[2].result);
            const daiBalance = new BigNumber(results[3].result);
            const userInfo = results[4];

            let stakedAlUsdBalance = new BigNumber(0);
            let tokensInBucket = new BigNumber(0);
            let pendingdivs = new BigNumber(0);

            if (userInfo.result) {
              stakedAlUsdBalance = new BigNumber(userInfo.result[0]);
              tokensInBucket = new BigNumber(userInfo.result[2]);
              pendingdivs = new BigNumber(userInfo.result[1]);
            }

            const busdDepositedInAlpavaVault = new BigNumber(results[5].result);

            const output = {
              transmuterDaiBalance: convertAmountFromRawNumber(transmuterDaiBalance, decimals),
              transmuterAlUsdBalance: convertAmountFromRawNumber(transmuterAlUsdBalance, decimals),
              alUsdBalance: convertAmountFromRawNumber(alUsdBalance, decimals),
              daiBalance: convertAmountFromRawNumber(daiBalance, decimals),
              stakedAlUsdBalance: convertAmountFromRawNumber(stakedAlUsdBalance, decimals),
              transmutableDaiBalance: convertAmountFromRawNumber(
                tokensInBucket.plus(pendingdivs),
                decimals
              ),
              alpacaDepositedInVault: convertAmountFromRawNumber(
                busdDepositedInAlpavaVault,
                decimals
              ),
            };

            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_SUCCESS,
              data: output,
              tokenId: tokenId,
            });
            resolve();
          })
          .catch(error => {
            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_FAILURE,
            });
            return reject(error.message || error);
          });
      }
    });

    return promise;
  };
}

export function useFetchTransmuterDetail() {
  const dispatch = useDispatch();

  const { details, fetchTransmuterDetailPending, fetchTransmuterDetailDone } = useSelector(
    state => ({
      details: state.transmuter.details,
      fetchTransmuterDetailDone: state.transmuter.fetchTransmuterDetailDone,
      fetchTransmuterDetailPending: state.transmuter.fetchTransmuterDetailPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchTransmuterDetail(data));
    },
    [dispatch]
  );

  const boundAction1 = useCallback(() => {
    return dispatch(fetchAllTransmuterDetail());
  }, [dispatch]);

  return {
    details,
    fetchTransmuterDetail: boundAction,
    fetchAllTransmuterDetail: boundAction1,
    fetchTransmuterDetailDone,
    fetchTransmuterDetailPending,
  };
}

export function reducer(state, action) {
  let { details } = state;
  switch (action.type) {
    case TRANSMUTER_DETAIL_FETCH_BEGIN:
      return {
        ...state,
        fetchTransmuterDetailPending: true,
      };

    case TRANSMUTER_DETAIL_FETCH_SUCCESS:
      details[action.tokenId] = action.data;
      return {
        ...state,
        details,
        fetchTransmuterDetailDone: true,
        fetchTransmuterDetailPending: false,
      };

    case TRANSMUTER_DETAIL_FETCH_FAILURE:
      return {
        ...state,
        fetchTransmuterDetailPending: false,
      };

    default:
      return state;
  }
}
