export const TRANSMUTER_DETAIL_FETCH_BEGIN = 'TRANSMUTER_DETAIL_FETCH_BEGIN';
export const TRANSMUTER_DETAIL_FETCH_SUCCESS = 'TRANSMUTER_DETAIL_FETCH_SUCCESS';
export const TRANSMUTER_DETAIL_FETCH_FAILURE = 'TRANSMUTER_DETAIL_FETCH_FAILURE';

export const TRANSMUTER_FETCH_STAKE_BEGIN = 'TRANSMUTER_FETCH_STAKE_BEGIN';
export const TRANSMUTER_FETCH_STAKE_SUCCESS = 'TRANSMUTER_FETCH_STAKE_SUCCESS';
export const TRANSMUTER_FETCH_STAKE_FAILURE = 'TRANSMUTER_FETCH_STAKE_FAILURE';

export const TRANSMUTER_FETCH_UNSTAKE_BEGIN = 'TRANSMUTER_FETCH_UNSTAKE_BEGIN';
export const TRANSMUTER_FETCH_UNSTAKE_SUCCESS = 'TRANSMUTER_FETCH_UNSTAKE_SUCCESS';
export const TRANSMUTER_FETCH_UNSTAKE_FAILURE = 'TRANSMUTER_FETCH_UNSTAKE_FAILURE';

export const TRANSMUTER_FETCH_TRANSMUTE_BEGIN = 'TRANSMUTER_FETCH_TRANSMUTE_BEGIN';
export const TRANSMUTER_FETCH_TRANSMUTE_SUCCESS = 'TRANSMUTER_FETCH_TRANSMUTE_SUCCESS';
export const TRANSMUTER_FETCH_TRANSMUTE_FAILURE = 'TRANSMUTER_FETCH_TRANSMUTE_FAILURE';

export const CHECK_ALUSD_APPROVAL_BEGIN = 'CHECK_ALUSD_APPROVAL_BEGIN';
export const CHECK_ALUSD_APPROVAL_SUCCESS = 'CHECK_ALUSD_APPROVAL_SUCCESS';
export const CHECK_ALUSD_APPROVAL_FAILURE = 'CHECK_ALUSD_APPROVAL_FAILURE';

export const FETCH_ALUSD_APPROVAL_BEGIN = 'FETCH_ALUSD_APPROVAL_BEGIN';
export const FETCH_ALUSD_APPROVAL_SUCCESS = 'FETCH_ALUSD_APPROVAL_SUCCESS';
export const FETCH_ALUSD_APPROVAL_FAILURE = 'FETCH_ALUSD_APPROVAL_FAILURE';

export const FETCH_FORCE_TRANSMUTER_BEGIN = 'FETCH_FORCE_TRANSMUTER_BEGIN';
export const FETCH_FORCE_TRANSMUTER_SUCCESS = 'FETCH_FORCE_TRANSMUTER_SUCCESS';
export const FETCH_FORCE_TRANSMUTER_FAILURE = 'FETCH_FORCE_TRANSMUTER_FAILURE';

export const FORCE_COLLECT_BEGIN = 'FORCE_COLLECT_BEGIN';
export const FORCE_COLLECT_SUCCESS = 'FORCE_COLLECT_SUCCESS';
export const FORCE_COLLECT_FAILURE = 'FORCE_COLLECT_FAILURE';