import React, { useEffect, useState } from 'react';
import YumInstruction from '../instruction/YumInstruction';
import AllStrategiesTable from './components/AllStrategiesTable.js';
import MyStrategiesTable from './components/MyStrategiesTable.js';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  useFetchAllVaultDetails
} from '../../features/vault/redux/hooks';
import { useConnectWallet } from 'features/home/redux/hooks';
import { useTVL } from '../../components/TVL/useTVL';
import { useFetchStrategyEarn } from 'features/info/redux/hooks';
const useStyles = makeStyles((theme) => ({

}));

export default function Strategies(props) {
  const { address } = useConnectWallet();
  const classes = useStyles();
  const { earnData, fetchStrategyEarn } = useFetchStrategyEarn()
  const { details, fetchAllVaultDetails } = useFetchAllVaultDetails();

  const { fetchP, tvls } = useTVL();

  useEffect(() => {
    if (address) {
      fetchAllVaultDetails()
      fetchStrategyEarn(address)
      // fetchP();
      const id = setInterval(() => {
        fetchAllVaultDetails()
        fetchStrategyEarn(address)

        // fetchP();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);
  return (
    <div >
      <YumInstruction value={0} />
      <div >
        <h1 className={"header"}>
          Your Strategies
        </h1>
        <MyStrategiesTable details={details} earnData={earnData} />
      </div>
      <div className="block">
        <h1 className={"header"}>
          All Strategies
        </h1>
        <AllStrategiesTable details={details} tvls={tvls} earnData={earnData} />
      </div>
    </div>
  );
}
