const initialState = {
  allowance: [0, 0, 0],
  checkApprovalPending: [false, false, false, false],
  fetchApprovalPending: [false, false, false, false],
  fetchStakePending: [false, false, false, false],
  fetchUnstakePending: [false, false, false, false],
  fetchClaimPending: [false, false, false, false],
  fetchExitPending: [false, false, false, false],
  context: {},
  deprecatedPoolContext: {},
  fetchPoolsDone: false,
  fetchPoolsPending: false,
  userDetails: [],
  fetchPoolUserDetailsDone: false,
  fetchPoolUserDetailsPending: false,
  rewardVestingDetails: [],
  fetchRewardVestingDetailsDone: false,
  fetchRewardVestingDetailsPending: false,
  fetchLockerApprovalPending: false,
  fetchWithdrawEarningPending: false,
  fetchLockerWithdrawPending: false,
  votingEscrowDetails: {
    pendingRewards: [],
  },
  fetchVotingEscrowDetailsPending: false,
  fetchVotingEscrowDetailsDone: false,
  fetchRewardVestingDetailsDone: false,
  fetchRewardVestingDetailsPending: false,
  fetchWithdrawEarningPending: false,
  singleAirdrop: {},
  singleLockerReward: {},
};

export default initialState;
