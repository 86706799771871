export const strategiesData = [
  {
    name: 'alpaca',
    strategyIcon: 'alpaca.png',
    tokenId: 'busd',
    asset1: 'BUSD',
    asset2: 'waBUSD',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/busd/alpaca',
    minterTo: '/app/minters/busd/wabusd/alpaca',
  },
  {
    name: 'alpaca',
    strategyIcon: 'alpaca.png',
    tokenId: 'wbnb',
    asset1: 'WBNB',
    asset2: 'waBNB',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/wbnb/alpaca',
    minterTo: '/app/minters/wbnb/wabnb/alpaca',
  },
];
