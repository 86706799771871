import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import styles from './styles';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';

const FarmInfo = ({ useContext, extra, rewardToken = 'WASABI', isLock = false }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const staked = useContext && !isNaN(useContext.yourStaked) ? useContext.yourStaked : '0';
  const rawStaked = useContext && !isNaN(useContext.rawStaked) ? useContext.rawStaked : '0';
  const tokenPrice =
    useContext && !isNaN(useContext.tokenPrice)
      ? new BigNumber(useContext.tokenPrice).toFormat(2)
      : 'Loading...';
  const depositValue =
    staked != '0' && !isNaN(useContext.tokenPrice)
      ? new BigNumber(parseFloat(staked) * useContext.tokenPrice).toFormat(0)
      : '0';
  const share =
    staked != '0'
      ? parseFloat((parseFloat(staked) * 100) / useContext.totalStaked).toFixed(2) + '%'
      : '0%';
  const dailyEarn =
    useContext && !isNaN(useContext.dailyEarn)
      ? new BigNumber(useContext.dailyEarn).toFormat(2)
      : 'Loading...';
  const showBoost = useContext && parseFloat(useContext.boost) != 0 && !isNaN(useContext.boost);
  const boost =
    useContext && !isNaN(useContext.boost) ? parseFloat(useContext.boost).toFixed(2) : 'Loading...';
  return (
    <div className={classes.card}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <div className={classes.textTitle}>{isLock ? 'Locked' : 'Deposited'}</div>
          <div className={classes.textValue}>
            {isLock
              ? `${parseFloat(staked).toFixed(2)} veWASABI (${parseFloat(rawStaked).toFixed(
                  2
                )} WASABI)`
              : `${parseFloat(staked).toFixed(2)}
                      ($${depositValue})`}
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.textTitle}>APR</div>
          <div className={classes.textValue}>
            {useContext && isFinite(parseFloat(useContext.apy))
              ? (parseFloat(useContext.apy) * 100).toFixed(2) +
                '%' +
                (isLock ? '' : ' - ' + (parseFloat(useContext.apy) * 2.5 * 100).toFixed(2) + '%')
              : 'Loading...'}
          </div>
        </Grid>
        {showBoost ? (
          <Grid item xs={12} md={4}>
            <div className={classes.textTitle}>
              Your boost
              <Tooltip
                placement="right"
                interactive
                title={
                  <Typography color="inherit">
                    You may boost your WASABI reward by up to 2.5x. The more WASABI you lock, the
                    more veWASABI you get and the higher boost you will have.{' '}
                    <a
                      target="_blank"
                      className={'labelLink'}
                      href="https://wasabix-finance.gitbook.io/wasabix_finance/wasabi-token#boosting-up-to-2-5-x"
                    >
                      {'More>>'}
                    </a>
                  </Typography>
                }
              >
                <IconButton className="tooltip">
                  <HelpIcon style={{ fontSize: '0.95rem' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.textValue}>{boost}x</div>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12} md={4}>
          <div className={classes.textTitle}>Share of pool</div>
          <div className={classes.textValue}>{share}</div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.textTitle}>Reward type</div>
          <div className={classes.textValue}>
            <img
              src={require('assets/img/' + _.toLower(rewardToken) + '.png')}
              className={'smIcon'}
            />
            {rewardToken}
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.textTitle}>Est. Daily earnings</div>
          <div className={classes.textValue}>${dailyEarn}</div>
        </Grid>
      </Grid>
      {extra ? extra : <div />}
    </div>
  );
};

export default FarmInfo;
