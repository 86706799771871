import React, { useEffect, useState } from 'react';
import Button from 'components/CustomButtons/Button.js';
import Tabs from 'components/CustomTabs/Tabs.js';
import { Grid, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import _ from "lodash";
import BigNumber from 'bignumber.js';
import {
  useParams,
  Link,
} from "react-router-dom";
import Borrow from './components/Borrow';
import Repay from './components/Repay';
import Liquidate from './components/Liquidate';
import { tokens } from '../configure';
import { useConnectWallet } from '../home/redux/hooks';
import { useFetchStrategyEarn } from 'features/info/redux/hooks';
import { strategiesData } from "features/configure"
import FeeField from 'features/fee/FeeField';
import {
  useFetchVaultDetail
} from '../vault/redux/hooks';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  earned: {
    fontWeight: 900,
    fontSize: "1rem",
    color: theme.palette.text.primary,
  },
  textTitle: {
    textAlign: "center",
    fontWeight: 900,
    paddingTop: 20,
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
  },
  textValue: {
    textAlign: "center",
    fontWeight: 900,
    padding: 20,
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
  },
  card: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30,
    padding: 20
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 16, fontWeight: 600,
    textDecoration: "underline"
  },


}));
export default function MinterPage(props) {
  const { tokenId, strategyId } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const { address } = useConnectWallet();
  const { details, fetchVaultDetail } = useFetchVaultDetail(tokenId, strategyId);
  const { earnData, fetchStrategyEarn } = useFetchStrategyEarn()
  useEffect(() => {
    if (address) {
      fetchVaultDetail(tokenId, strategyId);
      fetchStrategyEarn(address)
      const id = setInterval(() => {
        fetchVaultDetail(tokenId, strategyId);
        fetchStrategyEarn(address)
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, tokenId, strategyId]);
  const stratgy = _.find(strategiesData, { name: strategyId, asset1: _.toUpper(tokenId == "weth" ? "ETH" : tokenId) })

  const earnDataIndex = _.findIndex(earnData, { strategyId, tokenId });
  return (
    <>
      <Link to={"/app/minters"} className={classes.labelLink}>{"<< back to minters"}</Link>
      <div className={classes.card} >
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <div className="vaultHeader" style={{ position: "relative" }}>
            <img src={require('assets/img/' + _.toLower(stratgy.asset1) + ".png")} className={"lgIcon"} />
            <img src={require('assets/img/' + _.toLower(stratgy.asset2) + ".png")} className={"lgIcon"} style={{ marginLeft: -30, zIndex: 3 }} />
            <img src={require('assets/img/' + _.toLower(stratgy.name) + ".png")} className={"lgIcon"} style={{ marginLeft: -30, zIndex: 0, marginRight: 20 }} />
            {"Minter: " + stratgy.asset2 + " @ " + _.upperFirst(stratgy.name)}
          </div>
          <div className="vaultSubHeader">
            Take your strategies asset as collateral to borrow & mint waToken
          </div>
          <div className={classes.card} style={{ padding: 0 }}>
            <Grid container style={{ borderBottom: "1px solid white" }}>
              <Grid item xs={12} sm={4} >
                <div className={classes.textTitle}>
                  Issue Platform
                </div>
                <div className={classes.textValue} >
                  {_.upperFirst(strategyId)}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} >
                <div className={classes.textTitle}>
                  Collateral Asset
                </div>
                <div className={classes.textValue} >
                  {_.toUpper(tokenId)}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} >
                <div className={classes.textTitle}>
                  Borrow Asset
                </div>
                <div className={classes.textValue} >
                  {stratgy.asset2}
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={4} style={{ borderRight: "1px solid white" }}>
                <div className={classes.textTitle}>
                  Your {stratgy.asset2} debt
                </div>
                <div className={classes.textValue} >
                  {details.remainingDebt ? new BigNumber(details.remainingDebt).toFormat(2) : 'Loading...'}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} style={{ borderRight: "1px solid white" }}>
                <div className={classes.textTitle} >
                  {stratgy.asset2} in wallet
                </div>
                <div className={classes.textValue} >
                  {details.alusdBalance ? new BigNumber(details.alusdBalance).toFormat(2) : 'Loading...'}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} >
                <div className={classes.textTitle}>
                  Global Mintable {stratgy.asset2}
                </div>
                <div className={classes.textValue} >
                  {details.globalMintable ? new BigNumber(details.globalMintable).toFormat(2) : 'Loading...'}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.card}>
            <div className={classes.earned}>You've earned from {_.upperFirst(stratgy.name)}:
              <span style={{ float: "right" }}>{earnData && earnDataIndex >= 0 ? new BigNumber(earnData[earnDataIndex].user.totalUserVaultEarning).toFormat(1)
                : "Loading... "} {stratgy.asset2} Credit
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        This shows the amount of {stratgy.asset2} has been repaid by the strategy. The earnings are in {stratgy.asset2} credit.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <IconButton className={"tooltip"}>
                    <HelpIcon style={{ fontSize: "0.95rem" }} />
                  </IconButton>
                </Tooltip></span>
            </div>
          </div>
          <Tabs tabs={[
            {
              label: "Borrow/Mint",
              content: <Borrow />
            },
            {
              label: "Repay",
              content: <Repay />
            },
            {
              label: "Liquidate",
              content: <Liquidate />
            }
          ]} />
          <FeeField type="minter" />
          <Button fullWidth color="primary" to={"/app/farms"} style={{ marginTop: 30 }} component={Link}> Next step: Go to Farms </Button>
        </div>
      </div>

    </>
  );
}
