import { App } from '../features/home';
import { Landing } from '../features/landing';

import homeRoute from '../features/home/route';
import poolsRoute from '../features/pools/route';
import strategyRoute from '../features/strategy/route';
import minterRoute from '../features/minter/route';
import lockerRoute from '../features/locker/route';
import voteRoute from '../features/vote/route';
import transmuteRoute from '../features/collector/route';
import feeRoute from '../features/fee/route';
// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.
const childRoutes = [homeRoute, strategyRoute, minterRoute, poolsRoute, lockerRoute, voteRoute, transmuteRoute, feeRoute];


const routes = [
  {
    path: '/',
    component: Landing
  },
  {
    path: '/app',
    component: App,
    childRoutes: childRoutes,
  },
];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = route.childRoutes.find(child => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
