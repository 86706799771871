import BigNumber from 'bignumber.js';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';
import { useConnectWallet } from '../../home/redux/hooks';
import CustomToggle from 'components/CustomToggle/CustomToggle.js';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { useFetchVotingEscrowDetails, useFetchClaimReward } from 'features/pools/redux/hooks';
import { useFetchLockerRewards } from 'features/info/redux/hooks';
import {
  useFetchClaimAirdrops,
  useFetchSingleLockerReward,
  useFetchClaimMerkleReward,
} from 'features/pools/redux/hooks';
import moment from 'moment';
const styles = theme => ({
  detailContent: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    marginTop: 10,
  },

  value: {
    lineHeight: 1.5,
    textAlign: 'center',
    color: theme.palette.text.primary,
    margin: 18,
    fontSize: '1rem',
    fontWeight: 800,
  },
  cardTitle: {
    fontSize: '0.9rem',
    marginBottom: 30,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  cardValue: {
    fontSize: '0.9rem',
    fontWeight: 500,
    marginBottom: 30,
    color: theme.palette.text.secondary,
    textAlign: 'right',
  },
  reward: {
    textAlign: 'center',
    fontSize: '0.9rem',
    padding: 0,
    marginTop: 25,
    fontWeight: 600,
  },
  rewardTitle: {
    paddingBottom: '6px',
    borderBottom: '1px solid' + theme.palette.text.primary,
  },
  rewardValue: {
    padding: '20px 0',
    fontSize: '0.85rem',
  },
  selectedToggle: {
    padding: '5px 10px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.text.green,
      '& .MuiToggleButton-label': {
        color: 'black',
      },
      '&:hover': {
        backgroundColor: theme.palette.text.green,
        '& .MuiButton-label': {
          color: theme.palette.text.green,
        },
      },
    },
  },
});

const LockerReward = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [claimable, setClaimable] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address } = useConnectWallet();
  const { votingEscrowDetails } = useFetchVotingEscrowDetails();
  // const { fetchClaimReward, fetchClaimRewardPending } = useFetchClaimReward();
  const { fetchLockerRewards, lockerRewards } = useFetchLockerRewards();
  const { claimAirdrops } = useFetchClaimAirdrops();
  const { claimMerkleReward } = useFetchClaimMerkleReward();

  const { fetchSingleLockerReward, singleLockerReward } = useFetchSingleLockerReward();
  useEffect(() => {
    if (address) {
      fetchLockerRewards({ account: address });
      const id = setInterval(() => {
        fetchLockerRewards({ account: address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  const toggleClaimable = (event, newClaimable) => {
    setClaimable(newClaimable);
  };

  useEffect(() => {
    if (lockerRewards && lockerRewards.length > 0) {
      fetchSingleLockerReward({ address, web3, rewards: lockerRewards });

      const id = setInterval(() => {
        fetchSingleLockerReward({ address, web3, rewards: lockerRewards });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [lockerRewards]);

  const onClaimReward = reward => {
    if (reward.balanceTree) {
      claimMerkleReward({
        address,
        web3,
        reward,
      })
        .then(() => {
          enqueueSnackbar('Claim Rewards Success', { variant: 'success' });
          fetchLockerRewards({ account: address });
        })
        .catch(error => console.log(error));
    } else {
      claimAirdrops({
        address,
        web3,
        contractAddress: reward.contract,
      })
        .then(() => {
          enqueueSnackbar('Claim Rewards Success', { variant: 'success' });
          fetchLockerRewards();
        })
        .catch(error => console.log(error));
    }
  };
  const filteredRewards = lockerRewards
    ? _.filter(lockerRewards, function (reward) {
        let amount = _.get(singleLockerReward, `[${reward.contract}].availableAmount`);
        return isFinite(amount) && (claimable ? amount > 0 : amount == 0);
      })
    : '';
  const renderRewardCard = (reward, index) => {
    const initAmount = _.get(singleLockerReward, `[${[reward.contract]}].initAmount`);
    const totalRewards = reward.penaltyRatio + reward.feeRatio;
    const penaltyAmount = parseFloat((initAmount * reward.penaltyRatio) / totalRewards).toFixed(
      reward.toDecimal || 2
    );
    const feeAmount = parseFloat((initAmount * reward.feeRatio) / totalRewards).toFixed(
      reward.toDecimal || 2
    );
    const totalAmount = parseFloat(initAmount).toFixed(reward.toDecimal || 2);
    let availableAmount = _.get(singleLockerReward, `[${reward.contract}].availableAmount`);
    let wasabiPrice = reward.wasabiPrice;
    return (
      <div className="card" key={index} style={{ marginTop: 30 }}>
        <h1 className={'header'} style={{ textAlign: 'center', paddingTop: 0 }}>
          Week{reward.week} Distributed Date : {moment(reward.distributeDate).format()}
        </h1>
        <Grid container className={classes.reward} spacing={0}>
          <Grid item xs={12} md={3}>
            <span className={classes.rewardTitle}>Penalty Rewards</span>
            <div className={classes.rewardValue}>
              {initAmount
                ? `${penaltyAmount} WASABI = $${new BigNumber(penaltyAmount * wasabiPrice).toFormat(
                    1
                  )}`
                : '-'}
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <span className={classes.rewardTitle}>Fee Rewards</span>
            <div className={classes.rewardValue}>
              {initAmount
                ? `${feeAmount} WASABI = $${new BigNumber(feeAmount * wasabiPrice).toFormat(1)}`
                : '-'}
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <span className={classes.rewardTitle}>Rewards per Week</span>
            <div className={classes.rewardValue}>
              {initAmount
                ? `${totalAmount} WASABI = $${new BigNumber(totalAmount * wasabiPrice).toFormat(1)}`
                : '-'}
            </div>
          </Grid>
          <Grid item xs={12} md={1}>
            <span className={classes.rewardTitle}> APY</span>
            <div className={classes.rewardValue}>
              {parseFloat(reward.rewardsAPY * 100).toFixed(2)}%
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <span className={classes.rewardTitle}>veWASABI Value</span>
            <div className={classes.rewardValue}>${reward.veWasabiValue}</div>
          </Grid>
        </Grid>

        <Button
          fullWidth
          style={{ marginTop: 10 }}
          color="green"
          disabled={
            moment(reward.distributeDate) > moment() ||
            !availableAmount ||
            !isFinite(availableAmount) ||
            parseFloat(availableAmount) == 0
          }
          onClick={() => {
            onClaimReward(reward);
          }}
        >
          {availableAmount > 0 ? 'Claim Rewards Now' : 'Claimed'}
        </Button>
      </div>
    );
  };

  return (
    <>
      <div>
        <h1 className={'header'}>Airdrops Info</h1>
        <div className="card" style={{ padding: 30, paddingTop: 0 }}>
          <Grid
            container
            className={classes.reward}
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={4}>
              <img
                src={require('assets/img/airdrops.png')}
                style={{ width: '100%', maxWidth: 170 }}
                alt=""
              />
            </Grid>
            <Grid item md={8}>
              <h1 className={'header'} style={{ marginTop: 0 }}>
                Token Airdrops Available to Claim
              </h1>
            </Grid>
          </Grid>
          <br />
          <Button fullWidth color="green" component={Link} to="/app/airdrops">
            Go to Airdrops
          </Button>
        </div>
      </div>
      <div>
        <h1 className={'header'}>veWASABI Rewards</h1>
        <div className="card" style={{ minHeight: 330, padding: 30 }}>
          {votingEscrowDetails.userLocked > 0 ? (
            <>
              <div style={{ textAlign: 'right' }}>
                <CustomToggle
                  value={claimable}
                  onChange={toggleClaimable}
                  data={[
                    { text: 'Claimable', value: true },
                    { text: 'Claimed', value: false },
                  ]}
                />
              </div>
              {filteredRewards && filteredRewards.length > 0 ? (
                filteredRewards.map((reward, index) => renderRewardCard(reward, index))
              ) : (
                <h1 className={'header'} style={{ textAlign: 'center', marginTop: 80 }}>
                  No {claimable ? 'Claimable' : 'Claimed'} Rewards Now.
                </h1>
              )}
            </>
          ) : (
            <div style={{ position: 'relative' }}>
              <h1 className={'header'} style={{ textAlign: 'center', height: 250, paddingTop: 80 }}>
                No Locking veWASABI now. Please Lock your WASABI to get Rewards.
              </h1>
              <Button
                fullWidth
                style={{ position: 'absolute', bottom: -12, left: 0 }}
                color="green"
                onClick={() => {
                  window.scrollTo(0, 700);
                }}
              >
                Go To Lock Now
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LockerReward;
