export const tokens = {
  wasabi: {
    token: 'WASABI',
    address: '0x86e73212002f80C57070EfAd4765FF0117dE5aEa',
    decimals: 18,
  },
  wbnb: {
    token: 'WBNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
    correspondingMintableToken: 'waBNB',
  },
  wit: {
    token: 'WIT',
    address: '0xC9595e98cf8E8bB07bcc3a7E75C27acdCA950aeC',
    decimals: 18,
  },
  wvvt: {
    token: 'WVVT',
    address: '0x2f2c3459DaE9Cf208f5Ad6017f9A146382FE5704',
    decimals: 18,
  },
  wizt: {
    token: 'wizt',
    address: '0x091d07d5f57d27dFaDf032965d441B0275EdDCb2',
    decimals: 18,
  },
  wabusd: {
    token: 'waBUSD',
    address: '0x6D897D9C0902aC9399fFF708d1c201396342c80C',
    decimals: 18,
    baseToken: 'BUSD',
  },
  busd: {
    token: 'BUSD',
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    decimals: 18,
    correspondingMintableToken: 'waBUSD',
  },
  wabnb: {
    token: 'waBNB',
    address: '0x8aC4041d5801bC7cda5Df5Fd3af9f14546A9C223',
    decimals: 18,
    baseToken: 'wBNB',
  },
};
