import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import AllFarmsTable from './components/AllFarmsTable';
import VestedField from './components/VestedField';
import DeprecatedTable from './components/DeprecatedTable';
import { useTranslation } from 'react-i18next';
import { pools } from '../configure/pools';
import { useFetchPools } from './redux/fetchPools';
import { useConnectWallet } from './../home/redux/hooks';
import YumInstruction from 'features/instruction/YumInstruction';
import { useTVL } from 'components/TVL/useTVL';
import _ from 'lodash';
export default function PoolList() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();
  const { address } = useConnectWallet();
  const { context, fetchPools } = useFetchPools();
  const { poolTlv } = useTVL();
  let filteredPools = pools;

  const witAddresses = [
    '0x1CA1d9b9F7F0B35F8B4355aC26c1CE61a16aab27',
    '0xCfB6789E27577cB07dFc7e51907Ce8bD16923BB7',
    '0xEfB586dc3843DE400daa6ADe290F93d4B07eC57a',
    '0x462b5D15190678b9AF21e92316e683C368aa3ecF',
    '0xD58db499769676Be8200DCbC6c22e0a6Dd7Da68b',
    '0xd87aDa3116432284f4a4abCF42B6dC43F4605F55',
    '0xB8550d56d982085087b7AfbC5e45DdA9cddb500e',
  ];
  // const wvvtAddresses = ['0x9E04ca39Ac95414460DEFe886e5eBcB5087AC1e7'];
  if (witAddresses.indexOf(address) > -1) {
    filteredPools = pools;
  }
  // if (wvvtAddresses.indexOf(address) > -1) {
  //   filteredPools = pools.filter(pool => (pool.pid != 3 && pool.pid != 4));
  // }
  const selfPools = _.filter(filteredPools, function (o) {
    return context[o.pid] && parseFloat(context[o.pid].yourStaked) > 0;
  });

  useEffect(() => {
    if (address) {
      fetchPools();

      const id = setInterval(() => {
        fetchPools();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  return (
    <div>
      <YumInstruction value={2} />
      <VestedField onlyFirst={true} />
      <DeprecatedTable context={context} />
      <div className="block">
        <h1 className={classes.title}>Your Farms(v2)</h1>
        <AllFarmsTable context={context} pools={selfPools} poolTlv={poolTlv} isSelf={true} />
      </div>
      <div className="block">
        <h1 className={classes.title}>All Farms(v2)</h1>
        <AllFarmsTable context={context} pools={filteredPools} poolTlv={poolTlv} />
      </div>
    </div>
  );
}
