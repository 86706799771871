import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  useFetchFeeData
} from 'features/fee/redux/hooks';
import { useConnectWallet } from 'features/home/redux/hooks';
import { fee } from 'features/configure'
import CustomTable from 'components/CustomTable/CustomTable.js';
import {
  Link,
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: "1rem",
    lineHeight: 1.5
  },
  value: {
    fontWeight: 1000,
    textDecoration: "underline",
    color: theme.palette.text.primary
  }

}));

export default function FeeStructure(props) {
  const { address } = useConnectWallet();
  const classes = useStyles();
  const { feeData, fetchFeeData } = useFetchFeeData();
  const lastLevel = fee.length - 1
  useEffect(() => {
    if (address) {
      fetchFeeData()
      const id = setInterval(() => {
        fetchFeeData()
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);
  return (
    <div >
      <div >
        <h1 className={"header"}>
          Fee Structure
        </h1>
        <br />
        <div className={classes.description}>
          Your trading fee level is <span className={classes.value}>VIP {feeData ? feeData.userLevelData.level : "-"}</span>
        </div>
        <br />
        <div className={classes.description}>
          {feeData&&feeData.userLevelData.level != 9 ?(<>Upgrade to <span className={classes.value}>VIP {feeData ? feeData.nextLevelData.level : "-"}</span> by increasing your
          veWASABI holdings by <span className={classes.value}>
            {feeData && !isNaN(feeData.userVeWasabi) ? parseFloat(feeData.nextLevelData.range[0] - feeData.userVeWasabi).toFixed(2) : "-"}</span> veWASABI. <Link to={"/app/locker"} className={classes.value}>{"Increase Now>>"}</Link></>):(<>Welcome to the top level, you are the lord of Wasabix!</>)}
        </div>
      </div>
      <div className="block">
        <h1 className={"header"}>
          Fee Schedule
        </h1>
        < CustomTable headers={["\xa0\xa0\xa0Level\xa0\xa0\xa0", "\xa0\xa0veWASABI\xa0Balance\xa0\xa0", "Strategy Withdraw Fee", "Minter Fee", "Minter Interest Rate", "Farm Withdraw Fee", "Farm Early Withdraw Fee"]}
          type="outlined" leftText={[]} style={{ padding: "30px 0" }} selectedRow={feeData ? [feeData.userLevelData.level] : []}
          contents={fee.map((levelData, index) =>
            [
              "VIP" + levelData.level,
              `${index == 0 ? "<" : "≥"} ${levelData.range[index == 0 ? 1 : 0]} veWASABI`,
              parseFloat(levelData.strategyWithdrawFee * 100).toFixed(2) + "%",
              parseFloat(levelData.minterFee * 100).toFixed(2) + "%",
              "0%",
              parseFloat(levelData.farmWithdrawFee * 100).toFixed(2) + "%",
              parseFloat(levelData.farmEarlyWithdrawFee * 100).toFixed(2) + "%",
            ]
          )}
        />
      </div>
    </div>
  );
}
