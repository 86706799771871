import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from '../styles';
import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchTransmuterDetail } from '../redux/hooks';
import { strategiesData } from 'features/configure';
import { useFetchApys } from 'features/info/redux/fetchStrategyApy';
import _ from 'lodash';
import { convertAprFloatToString, converAprToApy } from 'features/helpers/utils';
import { contracts, tokens } from 'features/configure';

const useStyles = makeStyles(styles);
export default function CollectorStatus() {
  const classes = useStyles();
  const { tokenId } = useParams();
  const { address } = useConnectWallet();
  const { details, fetchTransmuterDetail } = useFetchTransmuterDetail();
  const { apys, fetchApys } = useFetchApys();

  let collectorsData = { wabusd: [], wabnb: [] };
  // let idleDaiDailyYield;
  // let pickleDaiDailyYield;
  // let vesperWbtcDailyYield;
  // let liquityLusdDailyYield;
  // let vesperWethDailyYield;

  // if (tokenId === 'wabusd') {
  useEffect(() => {
    if (address) {
      fetchTransmuterDetail(tokenId);

      const id = setInterval(() => {
        fetchTransmuterDetail(tokenId);
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, tokenId]);

  useEffect(() => {
    fetchApys();
  }, []);

  const strategies = _.filter(strategiesData, data => {
    return _.toLower(data.asste2) !== tokenId;
  });
  for (let strategy of strategies) {
    let totalDeposit = details[tokenId]
      ? Number(details[tokenId][strategy.name + 'DepositedInVault'])
      : 0;
    // let apy = strategy.apy
    let query = { tokenId: strategy.tokenId, strategyId: strategy.name };
    let apy = apys && _.find(apys, query) ? _.find(apys, query).apy : null;
    let dailyApy = (totalDeposit * converAprToApy(apy)) / 365;
    let displayApy = convertAprFloatToString(apy);

    collectorsData[_.toLower(strategy.asset2)].push({
      name: strategy.name,
      asset2: strategy.asset2,
      asset1: strategy.asset1,
      totalDeposit,
      apy,
      displayApy,
      dailyApy,
    });
  }
  // alpacaBusdDailyYield = alpacaBusdTotalDeposited * apy / 365;
  // idleDaiDailyYield = idleDaiTotalDeposited * idleDaiApyHardcoded / 365;
  // pickleDaiDailyYield = pickleDaiTotalDeposited * pickleDaiApyHardcoded / 365;
  // }
  // else if (tokenId === 'wabtc') {
  //   const vesperWbtcTotalDeposited = details['wabtc'] ? Number(details['wabtc'].wbtcDepositedInVesperDaiVault) : 0;
  //   const vesperWbtcApyHardcoded = Number(0.1361);
  //   vesperWbtcDailyYield = vesperWbtcTotalDeposited * vesperWbtcApyHardcoded / 365;
  // } else if (tokenId === 'walusd') {
  //   const liquityLusdTotalDeposited = details['walusd'] ? Number(details['walusd'].lusdDepositedInLiquityLusdVault) : 0;
  //   const liquityLusdApyHardcoded = Number(0.3716);
  //   liquityLusdDailyYield = liquityLusdTotalDeposited * liquityLusdApyHardcoded / 365;
  // } else if (tokenId === 'waeth') {
  //   const vesperWethTotalDeposited = details['waeth'] ? Number(details['waeth'].wethDepositedInVesperDaiVault) : 0;
  //   const vesperWethApyHardcoded = Number(0.078);
  //   vesperWethDailyYield = vesperWethTotalDeposited * vesperWethApyHardcoded / 365;
  // }

  return (
    <>
      <div className={classes.title}>Global Collector Status</div>
      <Grid container item justify="center" className={classes.detailContent}>
        <Grid item xs={6}>
          Total Staked {tokens[tokenId].token}:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>
          {!details[tokenId] || isNaN(details[tokenId].transmuterAlUsdBalance)
            ? 'Loading...'
            : parseFloat(details[tokenId].transmuterAlUsdBalance).toFixed(2)}
        </Grid>
        <Grid item xs={6}>
          Total {tokens[tokenId].baseToken} Available for Converting:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>
          {!details[tokenId] || isNaN(details[tokenId].transmuterDaiBalance)
            ? 'Loading...'
            : parseFloat(details[tokenId].transmuterDaiBalance).toFixed(2)}
        </Grid>
      </Grid>
      {collectorsData[tokenId].map(data => (
        <Grid container key={data.name}>
          <Grid item xs={12} className={classes.detailHeader}>
            {_.upperFirst(data.name)}
          </Grid>
          <Grid item xs={6}>
            {_.upperFirst(data.name)} {data.asset1} Vault APY:
          </Grid>
          <Grid item xs={6} className={classes.tableContent}>
            {data.displayApy}
          </Grid>
          <Grid item xs={6}>
            Total {data.asset1} Deposited in {_.upperFirst(data.name)} Vault:
          </Grid>
          <Grid item xs={6} className={classes.tableContent}>
            {isNaN(data.totalDeposit) ? 'Loading...' : parseFloat(data.totalDeposit).toFixed(2)}
          </Grid>
          <Grid item xs={6}>
            Estimated {_.upperFirst(data.name)} {data.asset1} Daily Yield:
          </Grid>
          <Grid item xs={6} className={classes.tableContent}>
            {isNaN(data.dailyApy) ? 'Loading...' : parseFloat(data.dailyApy).toFixed(2)}
          </Grid>
        </Grid>
      ))}
    </>
  );
}
