import _ from 'lodash';

// WASABI pool : 2
// WASABI-WBNB Pool : 20
// WIT: 30
// WVVT: 15
// WIZT: 33

export const pools = [
  {
    pid: 0,
    multiplier: '40X',
    stakedTokenName: 'Wasabi-BNB PLP',
    stakedTokenSymbol: 'WASABI-BNB PLP',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x4af56f065fab006721ab686086be206eba9d1abc',
    stakedTokenIcon: 'BSC_w_pancake.png',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x86e73212002f80C57070EfAd4765FF0117dE5aEa',
  },
  {
    pid: 1,
    multiplier: '15X',
    stakedTokenName: 'WABUSD',
    stakedTokenSymbol: 'waBUSD',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x6D897D9C0902aC9399fFF708d1c201396342c80C',
    stakedTokenIcon: 'shoyu-trans.png',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x86e73212002f80C57070EfAd4765FF0117dE5aEa',
  },
  {
    pid: 2,
    multiplier: '15X',
    stakedTokenName: 'waBUSD-BUSD PLP',
    stakedTokenSymbol: 'waBUSD-BUSD PLP',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x8aC4041d5801bC7cda5Df5Fd3af9f14546A9C223',
    stakedTokenIcon: 'wabusd_busd.png',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x86e73212002f80C57070EfAd4765FF0117dE5aEa',
  },
  {
    pid: 3,
    multiplier: '15X',
    stakedTokenName: 'WABNB',
    stakedTokenSymbol: 'waBNB',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x8aC4041d5801bC7cda5Df5Fd3af9f14546A9C223',
    stakedTokenIcon: 'wabnb.png',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x86e73212002f80C57070EfAd4765FF0117dE5aEa',
  },
  {
    pid: 4,
    multiplier: '15X',
    stakedTokenName: 'waBNB-WBNB PLP',
    stakedTokenSymbol: 'waBNB-WBNB PLP',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0xfcBa7FDCfEfF262fDAbFaa9eb2faaEa84Aee4636',
    stakedTokenIcon: 'wabnb-wbnb plp.png',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x86e73212002f80C57070EfAd4765FF0117dE5aEa',
  },
];
export const deprecatedPools = [
  {
    pid: 0,
    poolAddress: '0x3527640987c211df03737b2146b160ddbfcc14d2',
    stakedTokenName: 'Wasabi',
    stakedTokenSymbol: 'WASABI',
    stakedTokenDecimals: 18,
    apy: '153.99%',
    stakedTokenAddress: '0x86e73212002f80C57070EfAd4765FF0117dE5aEa',
    endTimestamp: 1624118150,
  },
  {
    pid: 1,
    poolAddress: '0x3527640987c211df03737b2146b160ddbfcc14d2',
    stakedTokenName: 'Wasabi-BNB PLP',
    stakedTokenSymbol: 'WASABI-BNB PLP',
    apy: '560.76%',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x4af56f065fab006721ab686086be206eba9d1abc',
    endTimestamp: 1624118150,
  },
];
