import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import { useTVL } from '../../components/TVL/useTVL';
import { pools } from '../configure/pools';
import { websiteUrl, currentChain } from 'features/configure';
import { useFetchPools } from '../pools/redux/fetchPools';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Notifications from 'components/Notifications/Notifications.js';
import VestedTable from 'features/pools/components/VestedTable';
import LockerInfo from 'features/locker/components/LockerInfo';
import { useFetchAllVaultDetails } from '../vault/redux/hooks';
import moment from 'moment';
import { useConnectWallet } from '../home/redux/hooks';
import LockerReward from 'features/locker/components/LockerReward';
import LockerField from 'features/locker/components/LockerField';
import VestedField from 'features/pools/components/VestedField';
import MyMintersTable from '../minter/components/MyMintersTable.js';
import AllFarmsTable from '../pools/components/AllFarmsTable.js';
import InvestChart from './components/InvestChart.js';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import PieChartIcon from '@material-ui/icons/PieChart';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useFetchAssetStats } from './redux/hooks';
const styles = theme => ({
  detailHeader: {
    fontSize: '1.4rem',
    textAlign: 'center',
    marginBottom: 30,
    marginTop: 10,
    fontWeight: 800,
  },
  strategyHeader: {
    fontSize: '1.1rem',
    fontWeight: 800,
    color: theme.palette.text.primary,
    marginTop: 15,
    marginBottom: 10,
  },
  metricsTitle: {
    fontSize: '0.8rem',
    marginTop: 18,
    marginBottom: 8,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  metricsValue: {
    fontSize: '0.9rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const Stats = ({}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const chainList = [
    { label: 'Ethereum', value: 'ETH' },
    { label: 'BSC', value: 'BSC' },
    { label: 'Polygon', value: 'POLYGON' },
  ];
  const [selectedChain, setSelectedChain] = useState(currentChain);
  const { fetchAssetStats, assetStatsData } = useFetchAssetStats();

  const { address } = useConnectWallet();
  const notifications = [
    {
      title: 'Welcome to WasabiX v2. If you have any question, please visit',
      linkTitle: 'here',
      linkUrl: 'https://wasabix-finance.gitbook.io/wasabix_finance/',
      fontColor: 'red',
    },
  ];
  // const { details, fetchAllVaultDetails } = useFetchAllVaultDetails();
  useEffect(() => {
    if (address) {
      fetchAssetStats(address);
      // fetchAllVaultDetails()
      const id = setInterval(() => {
        fetchAssetStats(address);
        // fetchAllVaultDetails()
      }, 10000);
      return () => clearInterval(id);
    } else {
    }
  }, [address]);
  const handleChange = event => {
    setSelectedChain(event.target.value);
  };
  
  const userStatsData = assetStatsData ? assetStatsData.stats : null
  const apiPoolData =
    userStatsData && userStatsData[selectedChain]
      ? _.filter(userStatsData[selectedChain].farms, function (o) {
          return o.userDeposited > 0;
        })
      : [{}];
  const filterVestedData =
    userStatsData && userStatsData[selectedChain] && userStatsData[selectedChain].vestingReward
      ? _.filter(userStatsData[selectedChain].vestingReward.vestingRewardData, function (o) {
          return o.reward > 1e-10;
        })
      : [];
  const votingEscrowDetails =
    userStatsData && userStatsData[selectedChain] ? userStatsData[selectedChain].locker : undefined;
  const strategiesData =
    userStatsData && userStatsData[selectedChain] ? userStatsData[selectedChain].strategies : {};
  let strategyNames = [];
  strategiesData &&
    Object.keys(strategiesData).map(tokenId => {
      const data = strategiesData[tokenId];
      Object.keys(data).map(strategyName => {
        let s = data[strategyName];
        s.tokenId = _.toLower(s.asset1);
        s.name = _.toLower(s.name);
        s.minterTo = '/minters/' + s.tokenId + '/' + _.toLower(s.asset2) + '/' + s.name;
        strategyNames.push(s);
      });
    });
  if (!assetStatsData || !userStatsData) {
    return (
      <div style={{ textAlign: 'center', marginTop: '30%' }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <div>
      <Notifications data={notifications} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <h1 className={'header'}>Your General Stats (Cross-Chain)</h1>
          <InvestChart assetStatsData={assetStatsData} />
        </Grid>
        <Grid item xs={12} md={4}>
          <h1 className={'header'}>Cross-Chain</h1>
          <div className={'card'} style={{ paddingTop: 0 }}>
            <div className={classes.metricsTitle}>
              You've Invested
              <Tooltip
                placement="right"
                interactive
                title={
                  <Typography color="inherit">
                    This shows the total amount of your investment in Wasabix Finance(Cross-Chain).
                    <br />
                    ETH: {new BigNumber(_.get(assetStatsData, 'totalInvested.eth', 0)).toFormat(2)}
                    <br />
                    BSC: {new BigNumber(_.get(assetStatsData, 'totalInvested.bsc', 0)).toFormat(2)}
                    <br />
                    Polygon:{' '}
                    {new BigNumber(_.get(assetStatsData, 'totalInvested.polygon', 0)).toFormat(2)}
                  </Typography>
                }
              >
                <IconButton className={'tooltip'}>
                  <PieChartIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.metricsValue}>
              $
              {assetStatsData && assetStatsData.totalInvested
                ? new BigNumber(assetStatsData.totalInvested.total).toFormat(2)
                : '-'}
            </div>
            <div className={classes.metricsTitle}>
              Accumulated Earnings
              <Tooltip
                placement="right"
                interactive
                title={
                  <Typography color="inherit">
                    Cross-chain earnings(Earnings = Earning from strategies + Earnings from Farm +
                    Earning from Penalty)
                    <br />
                    ETH: {new BigNumber(_.get(assetStatsData, 'totalEarning.eth', 0)).toFormat(2)}
                    <br />
                    BSC: {new BigNumber(_.get(assetStatsData, 'totalEarning.bsc', 0)).toFormat(2)}
                    <br />
                    Polygon:{' '}
                    {new BigNumber(_.get(assetStatsData, 'totalEarning.polygon', 0)).toFormat(2)}
                  </Typography>
                }
              >
                <IconButton className={'tooltip'}>
                  <PieChartIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.metricsValue}>
              $
              {assetStatsData && assetStatsData.totalEarning
                ? new BigNumber(assetStatsData.totalEarning.total).toFormat(2)
                : '-'}
            </div>
            <div className={classes.metricsTitle}>
              Accumulated ROI
              <Tooltip
                placement="right"
                interactive
                title={
                  <Typography color="inherit">
                    Accumulated Earnings/Investment
                    <br />
                    ETH:{' '}
                    {new BigNumber(_.get(assetStatsData, 'accumlatedROI.eth', 0) * 100).toFormat(2)}
                    %<br />
                    BSC:{' '}
                    {new BigNumber(_.get(assetStatsData, 'accumlatedROI.bsc', 0) * 100).toFormat(2)}
                    %<br />
                    Polygon:{' '}
                    {new BigNumber(
                      _.get(assetStatsData, 'accumlatedROI.polygon', 0) * 100
                    ).toFormat(2)}
                    %
                  </Typography>
                }
              >
                <IconButton className={'tooltip'}>
                  <PieChartIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.metricsValue}>
              {assetStatsData && assetStatsData.accumlatedROI
                ? new BigNumber(assetStatsData.accumlatedROI.total * 100).toFormat(2)
                : '-'}
              %
            </div>
            <div className={classes.metricsTitle}>
              Est. APY
              <Tooltip
                placement="right"
                interactive
                title={
                  <Typography color="inherit">
                    APY = ROI/time*1yr
                    <br />
                    ETH:{' '}
                    {new BigNumber(_.get(assetStatsData, 'currentAPY.eth', 0) * 100).toFormat(2)}%
                    <br />
                    BSC:{' '}
                    {new BigNumber(_.get(assetStatsData, 'currentAPY.bsc', 0) * 100).toFormat(2)}%
                    <br />
                    Polygon:{' '}
                    {new BigNumber(_.get(assetStatsData, 'currentAPY.polygon', 0) * 100).toFormat(
                      2
                    )}
                    %
                  </Typography>
                }
              >
                <IconButton className={'tooltip'}>
                  <PieChartIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.metricsValue}>
              {assetStatsData && assetStatsData.currentAPY
                ? new BigNumber(assetStatsData.currentAPY.total * 100).toFormat(2)
                : '-'}
              %
            </div>
            <div className={classes.metricsTitle}>
              Est. Earnings/day
              <Tooltip
                placement="right"
                interactive
                title={
                  <Typography color="inherit">
                    ETH: ${new BigNumber(_.get(assetStatsData, 'estDayEarning.eth', 0)).toFormat(2)}
                    <br />
                    BSC: ${new BigNumber(_.get(assetStatsData, 'estDayEarning.bsc', 0)).toFormat(2)}
                    <br />
                    Polygon: $
                    {new BigNumber(_.get(assetStatsData, 'estDayEarning.polygon', 0)).toFormat(2)}
                  </Typography>
                }
              >
                <IconButton className={'tooltip'}>
                  <PieChartIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.metricsValue}>
              $
              {assetStatsData && assetStatsData.estDayEarning
                ? new BigNumber(assetStatsData.estDayEarning.total).toFormat(2)
                : '-'}
            </div>
            <div className={classes.metricsTitle}>
              Est. Earnings/year
              <Tooltip
                placement="right"
                interactive
                title={
                  <Typography color="inherit">
                    ETH: {new BigNumber(_.get(assetStatsData, 'estYearEarning.eth', 0)).toFormat(2)}
                    <br />
                    BSC: {new BigNumber(_.get(assetStatsData, 'estYearEarning.bsc', 0)).toFormat(2)}
                    <br />
                    Polygon:{' '}
                    {new BigNumber(_.get(assetStatsData, 'estYearEarning.polygon', 0)).toFormat(2)}
                  </Typography>
                }
              >
                <IconButton className={'tooltip'}>
                  <PieChartIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.metricsValue}>
              $
              {assetStatsData && assetStatsData.estYearEarning
                ? new BigNumber(assetStatsData.estYearEarning.total).toFormat(2)
                : '-'}
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={'block'}>
        <h1 className={'header'}>
          Your Investment Details
          <CustomDropdown
            value={selectedChain}
            style={{ marginLeft: 15 }}
            data={chainList}
            onSelect={handleChange}
          />
        </h1>
        <div className={'card'}>
          {votingEscrowDetails && (
            <>
              <h1 className={classes.detailHeader}>Locking Status</h1>
              <div className={'card'}>
                <LockerInfo votingEscrowDetails={votingEscrowDetails} />
                <Button
                  color="green"
                  fullWidth
                  component={'a'}
                  href={websiteUrl[selectedChain] + '/locker'}
                >
                  Go to Locker
                </Button>
              </div>
              <div className="block" />
            </>
          )}
          <h1 className={classes.detailHeader}>Strategies</h1>

          <div className={'card'}>
            <h2 className={classes.strategyHeader}>Account: {address}</h2>
            {strategiesData &&
              Object.keys(strategiesData).map((tokenId, tIndex) => {
                const data = strategiesData[tokenId];
                // const hasValue = false
                // if (!hasValue) return;
                return (
                  <Grid container key={tIndex} style={{ paddingTop: 20 }}>
                    <Grid item xs={12} sm={1} style={{ textAlign: 'center' }}>
                      <img
                        src={require('assets/img/' + _.toLower(tokenId) + '.png')}
                        className={'icon'}
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={12} sm={11}>
                      <div
                        className={classes.strategyHeader}
                        style={{ textDecoration: 'underline' }}
                      >
                        {_.toUpper(tokenId)} Strategies
                      </div>
                      {Object.keys(data).map((strategyName, index) => {
                        const strategy = data[strategyName];
                        if (
                          isNaN(strategy.userDeposited) ||
                          parseFloat(strategy.userDeposited) < 1e-9
                        )
                          return;
                        const yearEarn = new BigNumber(strategy.userDeposited * strategy.APY);
                        return (
                          <div key={index}>
                            <div key={index} className={classes.strategyHeader}>
                              - {_.upperFirst(strategy.name)}:
                            </div>
                            <div className={classes.metricsTitle}>
                              You've invested:
                              <span className={classes.metricsValue}>
                                {' '}
                                {new BigNumber(strategy.userDeposited).toFormat(2)}
                              </span>
                            </div>
                            <div className={classes.metricsTitle}>
                              You've earned/ROI:
                              <span className={classes.metricsValue}>
                                {' '}
                                {strategy.userEarning
                                  ? new BigNumber(
                                      strategy.userEarning.totalUserVaultEarning
                                    ).toFormat(1) +
                                    '(' +
                                    new BigNumber(
                                      (strategy.userEarning.totalUserVaultEarning * 100) /
                                        strategy.userDeposited
                                    ).toFormat(1) +
                                    '%)'
                                  : 'Coming Soon'}
                              </span>
                            </div>
                            <div className={classes.metricsTitle}>
                              Est APY:
                              <span className={classes.metricsValue}>
                                {' '}
                                {parseFloat(strategy.APY * 100).toFixed(2)}%
                              </span>
                            </div>
                            <div className={classes.metricsTitle}>Your earning estimation:</div>
                            <CustomTable
                              headers={[
                                'Earnings/day',
                                'Earnings/week',
                                'Earnings/month',
                                'Earnings/year',
                              ]}
                              contents={[
                                [
                                  yearEarn.div(365).toFormat(2),
                                  yearEarn.div(52).toFormat(2),
                                  yearEarn.div(12).toFormat(2),
                                  yearEarn.toFormat(2),
                                ],
                              ]}
                              leftText={{}}
                              type="outlined"
                            />
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              })}
          </div>

          {/* </div> */}
          <div className="block">
            <h1 className={classes.detailHeader}>Farms</h1>
            <AllFarmsTable
              apiPoolData={apiPoolData}
              isSelf={true}
              linkPrefix={websiteUrl[selectedChain]}
            />
          </div>
          <div className="block">
            <h1 className={classes.detailHeader}>Minters</h1>
            <MyMintersTable
              strategies={strategyNames}
              details={strategiesData}
              linkPrefix={websiteUrl[selectedChain]}
            />
          </div>
          {/* <div className="block">
            <h1 className={classes.detailHeader}>
              veWASABI Rewards
            </h1>
            <LockerReward />
          </div> */}
          {filterVestedData.length > 0 && (
            <div className="block">
              <h1 className={classes.detailHeader}>Vesting Rewards</h1>
              <VestedTable data={filterVestedData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stats;
