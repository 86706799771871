import initialState from './initialState';
import { reducer as checkApprovalReducer } from './checkApproval';
import { reducer as fetchStakeReducer } from './fetchStake';
import { reducer as fetchUnstakeReducer } from './fetchUnstake';
import { reducer as fetchClaimReducer } from './fetchClaim';
import { reducer as fetchExitReducer } from './fetchExit';
import { reducer as fetchPoolsReducer } from './fetchPools';
import { reducer as fetchPoolUserDetailsReducer } from './fetchPoolUserDetails';
import { reducer as fetchRewardVestingDetailsReducer } from './fetchRewardVestingDetails';
import { reducer as fetchVotingEscrowDetailsReducer } from './fetchVotingEscrowDetails';
import { reducer as fetchDeprecatedPoolsReducer } from './fetchDeprecatedPools';
import { reducer as fetchLockerWithdrawReducer } from './fetchLockerWithdraw';
import { reducer as fetchCompetitionRewardReducer } from './fetchCompetitionReward';
import { reducer as fetchWithdrawCompetitionRewardReducer } from './fetchWithdrawCompetitionReward';
import { reducer as fetchClaimAirdropsReducer } from './fetchClaimAirdrops';
import { reducer as fetchSingleAirdropsReducer } from './fetchSingleAirdrop';
import { reducer as fetchSingleLockerRewardReducer } from './fetchSingleLockerReward';
import { reducer as fetchClaimMerkleRewardReducer } from './fetchClaimMerkleReward';
const reducers = [
  checkApprovalReducer,
  fetchStakeReducer,
  fetchUnstakeReducer,
  fetchClaimReducer,
  fetchExitReducer,
  fetchPoolsReducer,
  fetchPoolUserDetailsReducer,
  fetchRewardVestingDetailsReducer,
  fetchVotingEscrowDetailsReducer,
  fetchDeprecatedPoolsReducer,
  fetchLockerWithdrawReducer,
  fetchCompetitionRewardReducer,
  fetchWithdrawCompetitionRewardReducer,
  fetchClaimAirdropsReducer,
  fetchSingleAirdropsReducer,
  fetchSingleLockerRewardReducer,
  fetchClaimMerkleRewardReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
