import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_REWARD_VESTING_DETAILS_BEGIN,
  FETCH_REWARD_VESTING_DETAILS_SUCCESS,
  FETCH_REWARD_VESTING_DETAILS_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { rewardVestingABI, contracts } from '../../configure';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';
import moment from 'moment';

export function fetchRewardVestingDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_REWARD_VESTING_DETAILS_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { address, web3 } = home;

      const multicall = new MultiCall(web3, contracts.multicall.address);

      let calls = [];

      for (var i = 0; i < contracts.rewardVestings.length; i++) {
        const contract = new web3.eth.Contract(rewardVestingABI, contracts.rewardVestings[i].address);
        calls.push({ result: contract.methods.withdrawableEarning(address) });
        calls.push({ result: contract.methods.earnedBalances(address) });
        calls.push({ result: contract.methods.accumulatedPenalty() });
        calls.push({ result: contract.methods.vesting() });
      }

      multicall
        .all([calls])
        .then(([results]) => {
          let output = [];

          for (var i = 0; i < contracts.rewardVestings.length; i++) {
            let earningsDataRawResult = results[i * 4 + 1].result[1];
            let earningsData;
            if (earningsDataRawResult) {
              earningsData = earningsDataRawResult.map(data => {
                const unlockTimestamp = data[1];
                const endDate = moment.unix(unlockTimestamp)
                const startDate = moment(endDate).subtract(90, 'days')
                const remainingDays = endDate.diff(moment(), 'days')
                const amount = convertAmountFromRawNumber(data[0])
                return {
                  amount,
                  endDate: endDate.toDate(),
                  startDate: startDate.toDate(),
                  remainingDays
                }
              })
            }

            output[i] = {
              token: contracts.rewardVestings[i].token,
              address: contracts.rewardVestings[i].address,
              amount: convertAmountFromRawNumber(results[i * 4].result[0]),
              penaltyAmount: convertAmountFromRawNumber(results[i * 4].result[1]),
              amountWithoutPenalty: convertAmountFromRawNumber(results[i * 4].result[2]),
              totalEarningsInVesting: convertAmountFromRawNumber(results[i * 4 + 1].result[0]),
              earningsData: earningsData.length === 0 ? undefined : earningsData,
              totalPenalty: convertAmountFromRawNumber(results[i * 4 + 2].result),
              vestingDays: results[i * 4 + 3].result
            };


          }

          dispatch({
            type: FETCH_REWARD_VESTING_DETAILS_SUCCESS,
            data: output
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: FETCH_REWARD_VESTING_DETAILS_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchRewardVestingDetails() {
  const dispatch = useDispatch();

  const { rewardVestingDetails, fetchRewardVestingDetailsPending, fetchRewardVestingDetailsDone } = useSelector(state => ({
    rewardVestingDetails: state.pools.rewardVestingDetails,
    fetchRewardVestingDetailsPending: state.pools.fetchRewardVestingDetailsPending,
    fetchRewardVestingDetailsDone: state.pools.fetchRewardVestingDetailsDone

  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchRewardVestingDetails(data));
    },
    [dispatch]
  );

  return {
    rewardVestingDetails,
    fetchRewardVestingDetails: boundAction,
    fetchRewardVestingDetailsDone,
    fetchRewardVestingDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_REWARD_VESTING_DETAILS_BEGIN:
      return {
        ...state,
        fetchRewardVestingDetailsPending: true
      };

    case FETCH_REWARD_VESTING_DETAILS_SUCCESS:
      return {
        ...state,
        rewardVestingDetails: action.data,
        fetchRewardVestingDetailsDone: true,
        fetchRewardVestingDetailsPending: false
      };

    case FETCH_REWARD_VESTING_DETAILS_FAILURE:
      return {
        ...state,
        fetchRewardVestingDetailsPending: false
      };

    default:
      return state;
  }
}
