import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPools } from '../../features/pools/redux/action';
import { convertAmountFromRawNumber } from '../../features/helpers/bignumber';
import { fetchAllVaultDetails } from '../../features/vault/redux/action';
import { fetchAllTransmuterDetail } from '../../features/collector/redux/action';
import { strategiesData } from 'features/configure';
import BigNumber from 'bignumber.js';

export function useTVL() {
  const dispatch = useDispatch();

  const { tlv, tvls, poolL, totalLockedWasabi, poolTlv, cmc, cs, collectorTvls } = useSelector(
    state => {
      let wasabiPrice = state.price.priceData.wasabiPrice;
      let wbnbPrice = state.price.priceData.wbnbPrice;
      let lpData = state.price.priceData.lpData;
      let wasabiCirculatingMarketCap = state.price.priceData.wasabiCMC;
      let wasabiCirculatingSupply = state.price.priceData.wasabiCirculatingSupply;

      let lpTotalSupply = lpData && lpData.totalSupply ? new BigNumber(lpData.totalSupply) : 1;
      let reserve0 = lpData && lpData._reserve0 ? new BigNumber(lpData._reserve0) : 0;

      // pool 0 LP
      let poolZeroTLV = state.pools.context[0]
        ? parseFloat(
            new BigNumber(state.pools.context[0].totalStaked)
              .times(new BigNumber(state.pools.context[0].tokenPrice))
              .toString()
          )
        : 0;

      // // pool 1 (waBUSD)
      let poolOneTLV = state.pools.context[1]
        ? parseFloat(new BigNumber(state.pools.context[1].totalStaked).toString())
        : 0;

      // // pool 1 (waBUSD-BUSD plp)
      let poolTwoTLV = state.pools.context[2]
        ? parseFloat(new BigNumber(state.pools.context[2].totalStaked).times(2).toString())
        : 0;
      let pool3TLV = state.pools.context[3]
        ? parseFloat(new BigNumber(state.pools.context[3].totalStaked).times(wbnbPrice).toString())
        : 0;
      let pool4TLV = state.pools.context[4]
        ? parseFloat(
            new BigNumber(state.pools.context[4].totalStaked).times(wbnbPrice).times(2).toString()
          )
        : 0;

      let poolL = poolZeroTLV + poolOneTLV + poolTwoTLV + pool3TLV + pool4TLV;

      // let wasabiLockedPoolZero = state.pools.context[0] ? parseFloat(state.pools.context[0].totalStaked) : 0;
      let wasabiLockedPoolZero = state.pools.context[0]
        ? parseFloat(state.pools.context[0].totalStaked / lpTotalSupply) * reserve0
        : 0;

      let totalLockedWasabi = wasabiLockedPoolZero;
      let tvls = {};
      strategiesData.map(strategy => {
        let value = state.vault.details[strategy.tokenId][strategy.name]
          ? Number(state.vault.details[strategy.tokenId][strategy.name].totalLockedValue)
          : 0;
        if (strategy.tokenId == 'wbnb') {
          value *= wbnbPrice;
        }
        _.setWith(tvls, `[${strategy.tokenId}][${strategy.name}]`, value, Object);
      });

      // console.log(state.transmuter.details['wabusd']);
      let collectorBusdTlv = state.transmuter.details['wabusd']
        ? Number(state.transmuter.details['wabusd'].transmuterDaiBalance)
        : 0;
      let collectorWbnbTlv = state.transmuter.details['wabnb']
        ? Number(state.transmuter.details['wabnb'].transmuterDaiBalance) * wbnbPrice
        : 0;
      let collectorTvls = { collectorBusdTlv, collectorWbnbTlv };
      return {
        tlv: poolL,
        tvls,
        poolL: poolL,
        poolTlv: {
          0: poolZeroTLV,
          1: poolOneTLV,
          2: poolTwoTLV,
          3: pool3TLV,
          4: pool4TLV,
        },
        cmc: convertAmountFromRawNumber(wasabiCirculatingMarketCap),
        cs: convertAmountFromRawNumber(wasabiCirculatingSupply),
        collectorTvls,
        totalLockedWasabi: totalLockedWasabi,
      };
    }
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPools(data));
    },
    [dispatch]
  );
  const boundAction2 = useCallback(() => {
    return dispatch(fetchAllVaultDetails());
  }, [dispatch]);

  const boundAction3 = useCallback(() => {
    return dispatch(fetchAllTransmuterDetail());
  }, [dispatch]);

  return {
    fetchP: boundAction,
    fetchV: boundAction2,
    fetchT: boundAction3,
    tlv: tlv,
    poolTlv: poolTlv,
    tvls: tvls,
    cmc: cmc,
    cs: cs,
    totalLockedWasabi: totalLockedWasabi,
    collectorTvls,
    poolL: poolL,
  };
}
